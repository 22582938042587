import { makeStyles } from "@material-ui/styles";
import {commonStyles} from "../../../commonStyles";

const multiItemsSelectorStyles = (theme) => ({
  mainContainer: {
    display: 'flex',
  },
  leftSide: {
    display: 'flex',
    flexDirection: 'column',
    height: 'max-content',
    minWidth: 'max-content',
    maxWidth: 200,
    borderRight: `2px solid ${theme.palette.grey[300]}`,
  },
  rightSide: {
    width: 'max-content',
    flexWrap: 'wrap',
    display: 'flex',
    alignItems: 'start',
  },
  selector: {
    color: theme.palette.misis,
  },
  category: {
    marginLeft: 20,
  },
  selectedPath: {
    display: 'flex',
    alignItems: 'baseline',
    marginTop: theme.spacing(1),
  },
});

const multiItemsSelectorMobileStyles = (theme) => ({
});

export const useMultiItemsSelectorStyles = makeStyles(multiItemsSelectorStyles);
export const useMultiItemsSelectorMobileStyles = makeStyles(multiItemsSelectorMobileStyles);