import React, {useContext, useState} from 'react';
import {ImageList, ImageListItem, Dialog, Paper, useTheme} from '@material-ui/core';
import LeftIcon from '@material-ui/icons/ArrowBackIos';
import RightIcon from '@material-ui/icons/ArrowForwardIos';
import {DefaultImage} from '../../../components/DefaultImage';
import {useImageViewStyles, useImageViewMobileStyles} from './imageViewStyles';
import {AppContext} from "../../../common";

const imageVisibleNumber = 6;

export const ImageView = ({images}) => {
  const {isPhone} = useContext(AppContext);
  const theme = useTheme();
  const defaultClasses = useImageViewStyles();
  const mobileClasses = useImageViewMobileStyles();
  const classes = isPhone ? mobileClasses : defaultClasses;

  const [mainImageInd, setMainImageInd] = useState(0);
  const [openedImageInd, setOpenedImageInd] = useState(0);
  const [imageIsOpen, setImageIsOpen] = useState(false); //открыто ли фото в полный экран

  const handleChooseMain = (ind) => {
    setMainImageInd(ind);
    setOpenedImageInd(ind);
  };

  const handleClickMainImage = () => {
    setImageIsOpen(true);
    setOpenedImageInd(mainImageInd);
  };

  return (
    <div className={classes.container}>
      <Dialog style={{zIndex: theme.zIndex[20]}} open={imageIsOpen} onClose={() => setImageIsOpen(false)}>
        <Paper className={classes.openedImage}>
          <SideSlider classes={classes} zIndex={theme.zIndex[5]} images={images} ind={openedImageInd} handleChange={setOpenedImageInd}/>
          <img src={images[openedImageInd]} alt={"Modal"} className={classes.dialogImage}/>
        </Paper>
      </Dialog>
      <div className={classes.imageWrapper}>
        {images.length === 0 ? <DefaultImage/> :
          <>
            <div
              className={classes.imageCover}
              style={{backgroundImage: `url(${images[mainImageInd]})`}}
              onClick={handleClickMainImage}
            />
            <SideSlider classes={classes} zIndex={theme.zIndex[1]} images={images} ind={mainImageInd} handleChange={handleChooseMain}/>
          </>
        }
      </div>
      {images.length !== 0 &&
      <div className={classes.listRoot}>
        <ImageList
          cols={imageVisibleNumber}
          rowHeight={isPhone ? 80 : 110}
          className={classes.list}
          style={{margin: 0}}
        >
          {images.map((e, ind) =>
            <ImageListItem
              key={`item${ind}`}
              onClick={() => setMainImageInd(ind)}
              style={ind === mainImageInd ? {border: `2px solid ${theme.palette.misis}`} : {border: '2px solid transparent'}}
            >
              <img src={e} loading="lazy" alt={`image${ind}`} style={{cursor: 'pointer'}}/>
            </ImageListItem>
          )}
          {images.length < imageVisibleNumber && fillStubImages(imageVisibleNumber - images.length)}
        </ImageList>
      </div>
      }
    </div>
  );
};

const SideSlider = ({ind, images, handleChange, zIndex, classes}) => images.length > 1 &&
  <>
    <div className={classes.sideSlider} style={{left: 0, zIndex: zIndex}}
         onClick={() => handleChange(ind === 0 ? images.length - 1 : ind - 1)}>
      <LeftIcon/>
    </div>
    <div className={classes.sideSlider} style={{right: 0, zIndex: zIndex}}
         onClick={() => handleChange((ind + 1) % images.length)}>
      <RightIcon/>
    </div>
  </>

const fillStubImages = number => {
  let stubImages = new Array(number);
  for (let i = 0; i < number; i++) {
    stubImages.push(<ImageListItem></ImageListItem>)
  }
  return stubImages;
}