import { makeStyles } from '@material-ui/styles';

const priceSliderStyles = theme => ({
    textInputContainer: {
        display: 'flex',
        gap: '10px',
    },
    slider: {
        width: '90%',
        marginLeft: 10,
        color: theme.palette.misis,
    }
});
//`2px solid ${theme.palette.misisLight}`

export const usePriceSliderStyles = makeStyles(priceSliderStyles);