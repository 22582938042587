import React from "react";
import { makeStyles } from "@material-ui/core";
import { Image } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%', 
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.grey[300],
    "& :first-child": {
      width: "30%",
      height: "30%",
      color: theme.palette.grey[500],
    }
  },
}));

export const DefaultImage = ({className, ...props}) => {
  const classes = useStyles();
  
  return( 
    <div className={`${className} ${classes.root}`} {...props}>
      <Image/>
    </div>
  );
};