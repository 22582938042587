import React, {useContext, useState} from "react";
import {SideBar} from "../../components/side-bar/SideBar";
import {ButtonList} from "../../components/button-list/ButtonList";
import {ProductsPage} from "../../components/product-page/ProductsPage";
import {PaginationBar} from "../../components/pagination/Pagination";
import {AppContext, getCurrentUrlParams, ids} from "../../common";
import {sortItems} from "./productFeedUtils";
import {useProductFeedStyles} from "./productFeedStyles";
import {PageTitle} from "../../components/PageTitle";

export const ProductFeedView = (props) => {
  const {isPhone} = useContext(AppContext);
  const classes = useProductFeedStyles();

  return(
    isPhone
      ? <ProductFeedMobileView classes={classes} {...props}/>
      : <ProductFeedSimpleView classes={classes} {...props}/>
  );
}

const ProductFeedMobileView = (props) => {
  const {classes, goods, categories, dormitories, minMax, handleChooseSortItem, preview, sideBarPreview, empty, isFavorite} = props;
  const context = useContext(AppContext);
  const params = getCurrentUrlParams();
  const [sideBarIsOpen, setSideBarState] = useState(false);

  const sideBarCallback = isOpen => setSideBarState(isOpen);
  context.sideBarCallback = sideBarCallback;

  return (
    <div>
      <div className={classes.root}>
        <div
          className={`
            ${classes.sideBarContainerMobile} 
            ${sideBarIsOpen ? classes.sideBarOpenOnMobile : undefined}`
          }
        >
          {sideBarIsOpen &&
          <SideBar
            categories={categories}
            dormitories={dormitories}
            minMax={minMax}
            preview={sideBarPreview}
          />}
        </div>
        <div className={classes.pageMobile}>
          <ButtonList
              className={classes.buttonListMobile}
              id={ids.sortGoodsId}
              initialValue={params.sort_by}
              values={sortItems}
              handleChooseItem={handleChooseSortItem}
            />
          <ProductsPage
            preview={preview}
            empty={empty}
            goods={goods}
          />
          <PaginationBar className={classes.pagination} />
        </div>
      </div>
    </div>
  );
};

const ProductFeedSimpleView = (props) => {
  const {classes, goods, categories, dormitories, minMax, handleChooseSortItem, preview, sideBarPreview, empty, isFavorite} = props;
  const params = getCurrentUrlParams();

  return (
    <div>
      <div className={classes.root}>
        <div className={classes.sideBarContainer}>
          <SideBar
            categories={categories}
            dormitories={dormitories}
            minMax={minMax}
            preview={sideBarPreview}
          />
        </div>
        <div className={classes.page}>
          <PageTitle title={isFavorite ? "Избранное" : "Объявления"}/>
          <ButtonList
            id={ids.sortGoodsId}
            initialValue={params.sort_by}
            values={sortItems}
            handleChooseItem={handleChooseSortItem}
          />
          <ProductsPage
            preview={preview}
            empty={empty}
            goods={goods}
          />
          <PaginationBar className={classes.pagination} />
        </div>
      </div>
    </div>
  );
};