import React, { useEffect, useState, useContext } from "react";
import { Route, useHistory } from "react-router-dom";
import { checkToken, signIn } from "../api/accountAPI";
import {AppContext, getUser} from "../common";

export const AuthRoute = ({ component: Component, roles, ...props }) => {
  const history = useHistory();
  const context = useContext(AppContext);
  const user = getUser();
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const targetParts = props.path.split("/");
    let target_url = targetParts.length > 2 ? `/${targetParts[1]}/1` : props.path;
    const url = new URL(window.location.href);
    const vk_code = url.searchParams.get("code");
    url.searchParams.delete("code");
    context.updateUserInfo();

    !!vk_code
    ? signIn(vk_code, url.toString()).then(user_info => {
      setIsValid(true);
      history.replace(user_info.is_new ? "/profile?new_user=true" : history.location.pathname);
    })
    : checkToken().then((valid) => {
        if (!valid) {
          localStorage.removeItem("token");
          target_url = !!target_url ? target_url : "/page/1"; 
          history.replace(`/signin/?target_url=${target_url}`);
        }
        else {
          setIsValid(true);
        }
      })},
  [history.location]);

  window.scrollTo(0, 0);

  return (
    <Route {...props} render={(props) => isValid && (true || !roles || roles.includes(user?.role))
      ? <Component {...props}/>
      :
      <div>
        Доступ запрещен
      </div>}
    />
  );
};