import React from "react";
import {Paper, Typography} from "@material-ui/core";
import {getFullName} from "../../common";
import {Link} from "react-router-dom";
import {useModerationRequestCardStyles} from "./moderationRequestCardStyles";

export const ModerationRequestCard = ({data}) => {
  const classes = useModerationRequestCardStyles();
  const {good_name, creation_date, good_id, user_name, user_surname} = data;

  return (
    <Link to={`/request/${good_id}`} className={classes.link}>
    <Paper key={"rq" + good_id} className={classes.body}>
      <Typography><strong>Название: </strong>{good_name}</Typography>
      <Typography><strong>Владелец: </strong>{getFullName(user_name, user_surname)}</Typography>
      <Typography><strong>Дата создания: </strong>{creation_date}</Typography>
    </Paper>
    </Link>
  );
};