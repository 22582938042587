import { makeStyles } from "@material-ui/core/styles";
import {commonStyles} from "../../commonStyles";

const detailStyles = (theme) => ({
  ...commonStyles(theme),
  root: {
    margin: 'auto',
    minWidth: 950,
    width: '70%',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  goodInfo: {
    padding: "30px 0",
    display: 'flex',
    flexDirection: 'column',
  },
  twoColumnsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
  },
  leftSide: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 636,
    width: '60%',
  },
  subInfo: {
    display: 'flex',
    alignItems: 'center',
  },
});

const detailMobileStyles = (theme) => ({
  ...detailStyles(theme),
  root: {
    width: '95%',
    margin: 'auto',
    paddingBottom: theme.spacing(2),
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  twoColumnsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  leftSide: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  goodInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  subInfo: {
    display: 'flex',
    alignItems: 'center',
  },
});

export const useDetailMobileStyles = makeStyles(detailMobileStyles);
export const useDetailStyles = makeStyles(detailStyles);
