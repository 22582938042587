import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";

const useDialogStyle = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex[20],
  },
  buttonAccept: {
    color: theme.palette.error.main,
  },
}));

export const AlertDialog = ({
  title,
  text,
  confirmText,
  buttonText1,
  buttonText2,
  handleClose,
  handleAction,
}) => {
  const classes = useDialogStyle();

  const handleClickButton1 = () => {
    handleAction();
    handleClose();
  };

  return (
    <Dialog open={true} onClose={handleClose} classes={{ root: classes.root }}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {text}
          <br />
          {confirmText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClickButton1}
          color="primary"
        >
          {buttonText1}
        </Button>
        <Button onClick={handleClose} color="primary" className={classes.buttonAccept} autoFocus>
          {buttonText2}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
