import React from "react";
import {getUser} from "../common";

export const Secure = ({children, roles}) => {
  const user = getUser();

  return (
    roles.includes(user?.role)
      ? children
      : <></>
  );
};