import {makeStyles} from "@material-ui/core";

const ModerationRequestCardStyles = theme => ({
  body: {
    padding: 10,
    backgroundColor: theme.palette.grey[200],
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
    transition: 'background-color 500ms',
  },
  link: {
    textDecoration: "none",
    minWidth: 300,
    maxWidth: 500,
    width: '40%',
    height: 'max-content',
    margin: 20,
  },
});

export const useModerationRequestCardStyles = makeStyles(ModerationRequestCardStyles, {name: "ModerationRequestCardStyles"});