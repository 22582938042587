import React, {useState, useContext} from "react";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import {List, ListItemText, Collapse, ListItem, Paper, CircularProgress} from "@material-ui/core";
import {StyledButton} from "../StyledButton";
import {PriceSlider} from "../price-slider/PriceSlider";
import {SubList} from "../sub-list/SubList";
import {useSideBarStyles, useSideBarMobileStyles} from "./sideBarStyles";
import {AppContext, names, ids, getCurrentUrlParams} from "../../common";

export const SideBarView = (props) => {
  const context = useContext(AppContext);

  return context.isPhone ?
    <SideBarMobileView {...props} />
    :
    <SideBarSimpleView {...props} />
};

const SideBarSimpleView = (props) => {
  const {
    preview,
    handleSubmit,
    handleReset,
    minMaxPrice,
    values,
    maxCategoriesHeight,
  } = props;
  const classes = useSideBarStyles();
  const [isOpenCategories, openCategories] = useState(true);
  const params = getCurrentUrlParams();

  return (
      preview ?
        <Paper className={classes.preview}>
          <CircularProgress size={60}/>
        </Paper>
        :
        <div style={{height: "100%"}}>
          <Paper className={classes.root}>
            <List style={{margin: "0 20px", height: "90%"}}>
              <div id={ids.priceId}>
                <ListItemText primary={"Цена"} className={classes.header}/>
                <PriceSlider
                  min={minMaxPrice[0]}
                  max={minMaxPrice[1]}
                  initialValues={getActualInitialValues(
                    [+params.minPrice, +params.maxPrice],
                    minMaxPrice
                  )}
                  name={names.priceValuesName}
                />
              </div>
              <ListItem
                key={"Категории"}
                id={ids.listHeaderId}
                onClick={() => openCategories(!isOpenCategories)}
                className={classes.header}
              >
                <ListItemText primary={"Категории"}/>
                {isOpenCategories ? <ExpandLess/> : <ExpandMore/>}
              </ListItem>
              <Collapse
                in={isOpenCategories}
                timeout="auto"
                style={{overflowY: "auto", maxHeight: maxCategoriesHeight}}
              >
                {Object.keys(values.categories).map((e, ind) => (
                  <SubList
                    key={e + ind}
                    prefixId={"c"}
                    name={names.categoriesCheckboxName}
                    title={e}
                    items={values.categories[e]}
                    defaultSelected={params.categories}
                    classes={classes}
                    titleClass={classes.item}
                  />
                ))}
              </Collapse>
              <SubList
                prefixId={"d"}
                name={names.dormitoryCheckboxName}
                title="Общежитие"
                items={values.dormitories}
                defaultSelected={params.dormitories}
                classes={classes}
                titleClass={classes.header}
              />
              <div className={classes.submitContainer} id={ids.submitId}>
                <StyledButton
                  size="small"
                  className={classes.buttons}
                  text="Применить"
                  handleClick={handleSubmit}
                />
              </div>
              <div className={classes.resetContainer}>
                <StyledButton
                  variant="outlined"
                  size="small"
                  className={classes.buttons}
                  text="Сбросить"
                  handleClick={handleReset}
                />
              </div>
            </List>
          </Paper>
        </div>
  );
}
  ;

  const SideBarMobileView = (props) => {
    const {preview, handleSubmit, handleReset, minMaxPrice, values} = props;
    const classes = useSideBarMobileStyles();
    const [isOpenCategories, openCategories] = useState(true);
    const params = getCurrentUrlParams();

    return (
      <>
        {preview ? (
          <CircularProgress/>
        ) : (
          <Paper className={classes.root}>
            <List style={{margin: "0 20px"}}>
              <div id={ids.priceId}>
                <ListItemText primary={"Цена"} className={classes.header}/>
                <PriceSlider
                  min={minMaxPrice[0]}
                  max={minMaxPrice[1]}
                  initialValues={getActualInitialValues(
                    [+params.minPrice, +params.maxPrice],
                    minMaxPrice
                  )}
                  name={names.priceValuesName}
                />
              </div>
              <ListItem
                key={"Категории"}
                id={ids.listHeaderId}
                onClick={() => openCategories(!isOpenCategories)}
                className={classes.header}
              >
                <ListItemText primary={"Категории"}/>
                {isOpenCategories ? <ExpandLess/> : <ExpandMore/>}
              </ListItem>
              <Collapse in={isOpenCategories} timeout="auto">
                {Object.keys(values.categories).map((e, ind) => (
                  <SubList
                    key={e + ind}
                    prefixId={"c"}
                    name={names.categoriesCheckboxName}
                    title={e}
                    items={values.categories[e]}
                    defaultSelected={params.categories}
                    classes={classes}
                    titleClass={classes.item}
                  />
                ))}
              </Collapse>
              <SubList
                prefixId={"d"}
                name={names.dormitoryCheckboxName}
                title="Общежитие"
                items={values.dormitories}
                defaultSelected={params.dormitories}
                classes={classes}
                titleClass={classes.header}
              />
              <div className={classes.submitContainer} id={ids.submitId}>
                <StyledButton
                  size="small"
                  className={classes.buttons}
                  text="Применить"
                  handleClick={handleSubmit}
                />
              </div>
              <div className={classes.resetContainer}>
                <StyledButton
                  variant="outlined"
                  size="small"
                  className={classes.buttons}
                  text="Сбросить"
                  handleClick={handleReset}
                />
              </div>
            </List>
          </Paper>
        )}
      </>
    );
  };

  const getActualInitialValues = (initialValues, minMaxValues) => {
    let actualMin =
      !!!initialValues[0] ||
      initialValues[0] < minMaxValues[0] ||
      initialValues[0] > minMaxValues[1]
        ? minMaxValues[0]
        : initialValues[0];
    let actualMax =
      !!!initialValues[1] ||
      initialValues[1] > minMaxValues[1] ||
      initialValues[1] < minMaxValues[0]
        ? minMaxValues[1]
        : initialValues[1];
    return [actualMin, actualMax];
  };
