import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import './index.css';
import {App} from './App';
import {AppContext, subListMaxHeight} from './common';
import {ThemeProvider, createTheme} from '@material-ui/core/styles';

const zIndex = [];
zIndex[0] = -1;
zIndex[1] = 1;
zIndex[5] = 5;
zIndex[10] = 10;
zIndex[15] = 15;
zIndex[20] = 20;
zIndex[25] = 25;

const theme = createTheme({
  overrides: {
    MuiTypography: {
      root: {
        wordBreak: "break-word",
        textOverflow: "ellipsis",
      },
      colorPrimary: {
        color: '#000000',
      }
    },
    MuiLink: {
      underlineHover: {
        color: '#009fdf',
      }
    }
  },
  palette: {
    primary: {
      contrastText: '#ffffff',
      main: '#009fdf',
      light: '#5dc6f3',
    },
    misis: '#009fdf',
    misisLight: '#5dc6f3',
    misisPreLight: '#5ccbff',
    misisOrange: "#ff8201",
    misisYellow: "#f2aa00",
    misisGreen: "#6dc24b",
    misisLightPurple: "#9595d1",
  },
  zIndex: zIndex,
  height: {
    subListMaxHeight: subListMaxHeight,
  },
  width: {
    card: 175,
  },
  rootLeftMargin: 315,
});

ReactDOM.render(
  <AppContext.Provider value={AppContext}>
    <ThemeProvider theme={theme}>
        <React.StrictMode>
          <BrowserRouter>
            <App/>
          </BrowserRouter>
        </React.StrictMode>
    </ThemeProvider>
  </AppContext.Provider>,
  document.getElementById('root')
);
