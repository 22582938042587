import React from "react";
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  contained: {
    height: 'max-content',
    backgroundColor: theme.palette.misis,
    '&:hover': {
      backgroundColor: theme.palette.misisLight,
    },
    thansition: 'background-color 500ms',
  },
  outlined: {
    height: 'max-content',
    color: theme.palette.misis,
    borderColor: theme.palette.misis,
    '&:hover': {
      borderColor: theme.palette.misis,
    },
  },
}));

export const StyledButton = ({className, variant = "contained", style, type, size = 'small', text, icon, handleClick, disabled}) => {
  const classes = useStyles();
  const isOutlined = variant === "outlined";
  return (
    <Button
      disabled={disabled}
      className={`${isOutlined ? classes.outlined : classes.contained} ${className}`}
      style={style}
      variant={variant}
      disableElevation
      color="primary"
      type={type}
      size={size}
      endIcon={icon}
      onClick={handleClick}
    >
      {text}
    </Button>
  );
}
