import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {Add} from '@material-ui/icons';
import {ProductCard} from "../../components/product-card/horizontal/ProductCard";
import {ButtonList} from "../../components/button-list/ButtonList";
import {StyledButton} from "../../components/StyledButton";
import {InfoText} from "../../components/InfoText";
import {ScrollTopButton} from "../../components/ScrollTopButton";
import {useMyAdvertisementsMobileStyles, useMyAdvertisementsStyles} from "./myAdvertisementsStyles";
import {getMyGoods} from "../../api/productAPI";
import {AppContext, getCurrentUrlParams, goodsPerUser, handleChooseSortItem, ids, names, STATUS} from "../../common";
import {useMessages} from "../../components/messages";
import {PageTitle} from "../../components/PageTitle";
import {Divider} from "@material-ui/core";

const sortItems = [
  {value: 'Сначала новые', name: names.sortDescDate},
  {value: 'Сначала старые', name: names.sortAscDate},
];

export const MyAdvertisements = () => {
  const history = useHistory();
  const {showWarning} = useMessages();
  const {isPhone} = useContext(AppContext);
  const params = getCurrentUrlParams();
  const {showError} = useMessages();
  const defaultClasses = useMyAdvertisementsStyles();
  const mobileClasses = useMyAdvertisementsMobileStyles();
  const classes = isPhone ? mobileClasses : defaultClasses;

  const [data, setData] = useState(new Array(4));
  const [preview, setPreview] = useState(true);

  const sortItemHandler = value => handleChooseSortItem(history, value);

  const goodDeleted = id => setData(data.filter(e => e.id !== id));

  useEffect(() => {
      setPreview(true);
      getMyGoods(history)
        .then(response => setData(response))
        .catch(error => showError(error))
        .finally(() => setPreview(false))
    },
    [params.sort_by, history]);

  const handleClickAdd = () => {
    data.length >= goodsPerUser
      ? showWarning("Достигнуто максимальное количество опубликованных объявлений")
      : history.push('/add_product');
  };

  return (
    <>
      <div className={classes.root}>
        <PageTitle title="Мои объявления"/>
        <div className={classes.head}>
          <ButtonList
            style={{margin: 0}}
            className={classes.buttonList}
            id={ids.sortGoodsId}
            initialValue={params.sort_by}
            values={sortItems}
            handleChooseItem={sortItemHandler}
          />
          <StyledButton
            text='Добавить'
            icon={<Add/>}
            disabled={preview}
            handleClick={handleClickAdd}
          />
        </div>
        {data.map((e, ind) =>
          isPhone
            ? <ProductCard data={e} callback={goodDeleted} preview={preview} key={e?.id || `ind${ind}`}/>
            :
            <div className={classes.cardBlock}>
              <ProductCard data={e} callback={goodDeleted} preview={preview} key={e?.id || `ind${ind}`}/>
              {e?.comment && e.status === STATUS.AMENDING.value && !isPhone &&
                <ModerationFeedback comment={e.comment} className={classes.feedbackBlock}/>}
            </div>
        )}
        {!preview && data?.length === 0 && <InfoText text="У вас еще нет опубликованных объявлений."/>}
        {isPhone
          ? <ScrollTopButton className={classes.scrollButton}/>
          : <ScrollTopButton/>
        }
      </div>
    </>
  );
};

const ModerationFeedback = ({comment, ...props}) => {
  return (
    <div {...props}>
      <Divider orientation="vertical"/>
      <div>
        <h3>Комментарий модератора</h3>
        <p>{comment}</p>
      </div>
    </div>
  );
};