import { makeStyles } from "@material-ui/core/styles";

const sideBarStyles = (theme) => ({
  root: {
    position: "sticky",
    width: "100%",
    height: "100%",
    minWidth: "230px",
    maxWidth: "370px",
    minHeight: "90vh",
    zIndex: theme.zIndex[10],
  },
  subList: {
    width: "100%",
    maxHeight: theme.height.subListMaxHeight,
    height: "110%",
    overflow: "auto",
  },
  header: {
    padding: 0,
    "& span": {
      "font-weight": "bold",
      fontSize: 20,
    },
  },
  item: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  subItem: {
    padding: 0,
    marginLeft: "15px",
    width: "fit-content",
    height: "fit-content",
  },
  name: {
    width: "fit-content",
  },
  submitContainer: {
    display: "flex",
    marginTop: "20px",
    justifyContent: "center",
  },
  resetContainer: {
    display: "flex",
    marginTop: "10px",
    justifyContent: "center",
  },
  buttons: {
    width: '90%',
  },
  preview: {
    display: 'flex',
    width: 230,
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const sideBarMobileStyles = (theme) => ({
  ...sideBarStyles(theme),
  root: {
    position: "relative",
    height: "auto",
    minHeight: '100vh',
    zIndex: theme.zIndex[10],
    paddingBottom: 100,
  },
});

export const useSideBarStyles = makeStyles(sideBarStyles);
export const useSideBarMobileStyles = makeStyles(sideBarMobileStyles);
