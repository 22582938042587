export const DATA_DESCRIPTION = {
  Телефон: {
    fieldName: "phone_number",
    editable: true,
    visibleName: "phone_is_visible",
    check: true,
  },
  Общежитие: {
    fieldName: "dormitory_name",
    editable: true,
    visibleName: null,
  },
  "Номер комнаты": {
    fieldName: "room_number",
    editable: true,
    visibleName: "room_is_visible",
  },
  Почта: {
    fieldName: "email",
    editable: true,
    visibleName: "email_is_visible",
    check: true,
  },
  Telegram: {
    fieldName: "telegram_nick",
    editable: true,
    visibleName: "telegram_is_visible",
    check: true,
  },
};

export const getRequestObject = (source) => ({
  email: source.email,
  phone_number: source.phone_number,
  room_number: source.room_number,
  telegram_nick: source.telegram_nick,
  email_is_visible: source.email_is_visible,
  phone_is_visible: source.phone_is_visible,
  telegram_is_visible: source.telegram_is_visible,
  room_is_visible: source.room_is_visible,
  id_dormitory: source.id_dormitory,
});

export const getIdByName = (array, name) =>
  Array.isArray(array) && !!name ? array.find((e) => e.name == name).id : 0;
