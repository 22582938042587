import {makeStyles} from "@material-ui/core/styles";

const productCardStyles = (theme) => ({
  card: {
    width: 830,
    display: "flex",
    marginTop: 15,
    height: 220,
    borderLeft: `8px solid ${theme.palette.misis}`,
    "&:hover": {
      borderLeft: `8px solid ${theme.palette.misisLight}`,
    },
    transition: "border 500ms",
  },
  link: {
    display: "flex",
    textDecoration: "none",
    color: "black",
  },
  media: {
    display: "block",
    width: "250px",
    paddingTop: "200px",
    flexGrow: 0,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    marginLeft: 10,
    width: "inherit",
  },
  price: {
    display: 'flex',
    width: '100%',
    position: "absolute",
    bottom: 5,
  },
  actionContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "5px",
  },
  name: {
    marginTop: 10,
    height: 38,
    width: 470,
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    "-webkit-line-clamp": 1,
  },
  category: {
    color: "rgba(0, 0, 0, 0.54)",
    marginRight: "5px",
    height: "20px",
  },
  button: {
    margin: "2px 0",
    color: "white",
    fontSize: 12,
    transition: "background-color 500ms",
  },
  buttonDelete: {
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.light,
    },
  },
  buttonEdit: {
    backgroundColor: theme.palette.warning.main,
    "&:hover": {
      backgroundColor: theme.palette.warning.light,
    },
  },
  defaultImage: {
    width: 250,
    height: 220,
  },
  statusContainer: {
    marginLeft: 'auto',
  },
  moderatorComment: {
    padding: theme.spacing(2),
  }
});

const productCardMobileStyles = theme => ({
  card: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 15,
    borderLeft: `8px solid ${theme.palette.misis}`,
    '&:hover': {
      borderLeft: `8px solid ${theme.palette.misisLight}`
    },
    transition: 'border 500ms',
  },
  link: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 6,
    textDecoration: "none",
    color: 'black',
  },
  media: {
    display: 'block',
    width: "100%",
    paddingTop: 260,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    marginLeft: 10,
  },
  price: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
  },
  status: {
    marginTop: theme.spacing(1),
    width: 'max-content',
  },
  reasonButton: {
    height: 24,
    fontSize: "10pt",
    verticalAlign: "bottom",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    color: theme.palette.misisOrange,
  },
  actionContainer: {
    display: 'flex',
    margin: '5px'
  },
  category: {
    color: "rgba(0, 0, 0, 0.54)",
    marginRight: "5px",
    height: "20px"
  },
  button: {
    marginLeft: theme.spacing(1),
    color: 'white',
    fontSize: 12,
    transition: 'background-color 500ms'
  },
  buttonDelete: {
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.light
    },
  },
  buttonEdit: {
    backgroundColor: theme.palette.warning.main,
    "&:hover": {
      backgroundColor: theme.palette.warning.light
    }
  },
  defaultImage: {
    width: '100%',
    height: 260,
  },
  moderatorComment: {
    padding: theme.spacing(2),
  }
});

export const useProductCardMobileStyles = makeStyles(productCardMobileStyles);
export const useProductCardStyles = makeStyles(productCardStyles);