import React, {useContext} from "react";
import { Switch, Route, Redirect } from 'react-router-dom';
import { SignIn } from './pages/sign-in/SignIn';
import { Detail } from './pages/detail/Detail';
import { AddProduct } from './pages/add-product/AddProduct'
import { ProductFeed } from "./pages/product-feed/ProductFeed";
import { MyAdvertisements } from "./pages/my-advertisements/MyAdvertisements";
import { NavigationBar } from "./components/navigation-bar/NavigationBar";
import { Profile } from "./pages/profile/Profile";
import { AuthRoute } from "./components/AuthRoute";
import { BaseRoute } from "./components/BaseRoute";
import { useMediaQuery } from "@material-ui/core";
import { AppContext } from "./common";
import "./App.css";
import {Message} from "./components/messages";
import {Footer} from "./components/Footer";
import {MODERATOR, ADMIN} from "./secure";
import {ModerationRequestListPage} from "./pages/moderation-request-list/ModerationRequestListPage";
import {ModerationRequest} from "./pages/moderation-request/ModerationRequest";
import {NotFound} from "./components/not-found/NotFound";

export function App() {
  const context = useContext(AppContext);
  context.isPhone = useMediaQuery('(max-width: 768px)');

  return (
    <>
      <div style={{minHeight: '100vh', height: '100%'}}>
        <NavigationBar/>
        <Message/>
        <Switch>
          <AuthRoute exact path="/edit/:id" component={AddProduct}/>
          <AuthRoute exact path="/add_product" component={AddProduct}/>
          <BaseRoute exact path="/detail/:id" component={Detail}/>
          <Route exact path="/signin" component={SignIn}/>
          <AuthRoute exact path="/profile" component={Profile}/>
          <BaseRoute exact path="/page/:page" component={ProductFeed}/>
          <AuthRoute exact path="/fav/:page" component={ProductFeed}/>
          <AuthRoute exact path="/myadv" component={MyAdvertisements}/>
          <AuthRoute exact path="/moderation" roles={[MODERATOR, ADMIN]} component={ModerationRequestListPage}/>
          <AuthRoute exact path="/request/:id" roles={[MODERATOR, ADMIN]} component={ModerationRequest}/>
          <Redirect from="/" to="/page/1" exact={true}/>
          <Route path="*" component={NotFound}/>
        </Switch>
      </div>
      <Footer/>
    </>
  );
}