import {goodsPerUser} from "../../common";

export const DATA_DESCRIPTION = (isCreate) => (
  {
    id_subcategory: 0,
    id_dormitory: 0,
    price: 0,
    good_name: "",
    description: "",
    images: isCreate ? [] : [{data_url: ""}],
  });

export const MESSAGES = {
  validation_error: "Одно или несколько фоток не прошло проверку.",
  success: "Объявление отправлено на модерацию",
  goods_count_error: `Нельзя создать больше ${goodsPerUser} товаров`,
};

export const MAX_PRICE = 1000000;

export const isEquals = (left, right) => Object.keys(left).filter(e =>
  e === 'images'
    ? left[e].length === right[e].length &&
    right[e].filter((file, ind) => file.data_url !== left.images[ind].data_url).length === 0
    : left[e] == right[e]
).length === Object.keys(right).length;

export const convertFiles = (data, name, setFieldValue) => {
  if (data.length === 0) {
    setFieldValue(name, []);
  }
  const images = [];
  const result = [];

  new Promise((resolve) => data.forEach((url, ind) =>
    urlToFileFormat(url, ind)
      .then((file) => {
        images.push({'data_url': url, file});
        data.length === images.length && resolve();
      })
  ))
    .then(() => { //установление нужного порядка фото, т.к. первыми приходят те, у которых меньше размер
      data.forEach((url, ind) => result[ind] = images.find(image => image.data_url === url));
      setFieldValue(name, result);
    });
};

const urlToFileFormat = (url, ind) => fetch(url)
  .then(res => res.blob())
  .then(blob => new File([blob], `image${ind}.webp`, {type: 'image/webp'}));

export const getRemovedUrls = (initialImages, actualImages) => {
  const actualUrls = actualImages.map(image => image.data_url);
  return initialImages
    .map(image => image.data_url)
    .filter(url => !actualUrls.includes(url));
};

export const getAddedFiles = (images) => images
  .filter(image => !image.data_url.startsWith('https://res.cloudinary.com'))
  .map(image => image.file);