import React, {useContext, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {Card, CardActions, CardContent, CardMedia, Chip, Typography, useTheme} from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import {IconButtonTooltip} from "../../IconButtonTooltip";
import {AlertDialog} from "../../AlertDialog";
import {DefaultImage} from "../../DefaultImage";
import {useProductCardMobileStyles, useProductCardStyles} from "./productCardStyles";
import {AppContext, createCategoryViewName, isAdult, tobaccoProductSubcategory} from '../../../common';
import {changeFavorite} from "../../../api/productAPI";
import {Skeleton} from "@material-ui/lab";

export const ProductCard = ({data, preview}) => {
  const {isPhone} = useContext(AppContext);
  const theme = useTheme();
  const history = useHistory();
  const defaultClasses = useProductCardStyles();
  const mobileClasses = useProductCardMobileStyles();
  const classes = isPhone ? mobileClasses : defaultClasses;
  
  const [inFavorite, setFavorite] = useState(data?.is_favorite);
  const [cursorInside, setCursorInside] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleClickFavorite = () => 
    changeFavorite(+data.id)
    .then(() => setFavorite(!inFavorite))
    .catch(() => setOpenDialog(true));

  return (preview
    ? <Skeleton
        variant="rect"
        width={isPhone ? (theme.width.card - 30) : 280}
        height={isPhone ? 300 : 450}
        style={isPhone ? {} : {borderRadius: 15}}
      />
    :
    <div className={classes.divContainer}>
      <Card
        elevation={2}
        className={classes.root}
        classes={{root: (cursorInside ? classes.cardHovered : undefined)}}
        raised
        onMouseEnter = {() => setCursorInside(true)}
        onMouseLeave = {() => setCursorInside(false)}
      >
        <Link to={`/detail/${data.id}`} style={{textDecoration: "none"}}>
        {data.images.length === 0
          ? <DefaultImage className={classes.defaultImage}/>
          : <CardMedia
            className={classes.media}
            image={data.images[0]}
            style={data.subcategory === tobaccoProductSubcategory && !isAdult() && {filter: "blur(5px)"}}
          />
        }
        <CardContent className={classes.content} style={{paddingBottom: 0}}>
          <Chip
            className={classes.category}
            size="small"
            variant="outlined"
            label={createCategoryViewName(data.category, data.subcategory)}
          />
            <Typography className={classes.name} variant={isPhone ? "subtitle2" : "h6"} color="primary">
              {data.good_name}
            </Typography>
          <Typography className={classes.price} variant={isPhone ? "h6" : "h5"} color="textPrimary">
              {data.price} ₽
          </Typography>
        </CardContent>
        </Link>
        <CardActions className={classes.bottomPart}>
          <Typography 
            className={classes.date}
            variant={isPhone ? "caption" : "subtitle2"}
            color="textSecondary"
          >
            {data.date}
          </Typography>
          <Chip className={classes.dormitory} size="small" label={data.dormitory}/>
          <IconButtonTooltip
            title={inFavorite ? "Убрать из избранного" : "В избранное"}
            className={classes.heart}
            style={{marginLeft: "auto", padding: 0}}
            onClick={handleClickFavorite}
            icon={inFavorite ?
              <FavoriteIcon className={classes.heart}/>
              : 
              <FavoriteBorderIcon className={classes.heart}/>}
          />
        </CardActions>
      </Card>
      {openDialog &&
      <AlertDialog
        title="Авторизация"
        text="Это действие доступно только авторизованным пользователям."
        confirmText="Хотите авторизоваться?"
        buttonText1="Да"
        buttonText2="Нет"
        handleClose={() => setOpenDialog(false)}
        handleAction={() => history.push('/signin?target_url=/page/1')}
      />}
    </div>
  );
}