import { makeStyles } from "@material-ui/styles";

const imageDragBoxStyles = (theme) => ({
  root: {
    display: "flex",
    margin: "20px 0",
  },
  mainContainer: {
    display: "flex", 
    flexDirection: "column",
  },
  imageHarness: {
    display: "flex",
    flexDirection: "row",
    flexWrap: 'wrap',
    gap: 20,
  },
  uploadButton: {
    width: 160,
    height: 160,
    borderRadius: "15px",
    border: "dashed " + theme.palette.misis,
    backgroundColor: "transparent",
    cursor: 'pointer',
    transition: 'background-color 500ms',
  },
  dragging: {
    backgroundColor: theme.palette.grey[200],
  },
  imageContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.palette.grey[300],
    overflow: 'hidden',
    borderRadius: "15px",
    width: 160,
    height: 160,
    cursor: 'pointer',
  },
  deleteAllButton: {
    width: 160,
  },
  image: {
    width: '100%',
  },
  imageActions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    '& button': {
      padding: 7,
      color: 'black',
      '& svg': {
        fontSize: 18,
      },
    },
  },
  openedImage: {
    zIndex: theme.zIndex[15],
  },
  dialogImage: {
    maxWidth: '100%',
    maxHeight: '80vh',
  },
  statusSuccess: {
    position: 'absolute',
    left: '83%',
    top: '83%',
    color: theme.palette.success.main,
    zIndex: theme.zIndex[10],
  },
  statusError: {
    position: 'absolute',
    left: '83%',
    top: '83%',
    color: theme.palette.error.main,
    zIndex: theme.zIndex[10],
  },
});

const imageDragBoxMobileStyles = (theme) => ({
  ...imageDragBoxStyles(theme),
  root: {
    display: "flex",
    justifyContent: 'center',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  imageHarness: {
    display: "flex",
    flexDirection: "row",
    flexWrap: 'wrap',
    gap: 20,
    justifyContent: 'center',
  },
  deleteAllButton: {
    width: 160,
    margin: 'auto',
  },
  imageContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.palette.grey[300],
    overflow: 'hidden',
    borderRadius: "15px",
    width: 130,
    height: 130,
    cursor: 'pointer',
  },
  uploadButton: {
    width: 130,
    height: 130,
    borderRadius: "15px",
    border: "dashed " + theme.palette.misis,
    backgroundColor: "transparent",
    cursor: 'pointer',
  },
  statusSuccess: {
    ...imageDragBoxStyles(theme).statusSuccess,
    left: '80%',
    top: '80%',
  },
  statusError: {
    ...imageDragBoxStyles(theme).statusError,
    left: '80%',
    top: '80%',
  },
});

export const useImageDragBoxMobileStyles = makeStyles(imageDragBoxMobileStyles);
export const useImageDragBoxStyles = makeStyles(imageDragBoxStyles);
