import { makeStyles } from "@material-ui/core/styles";

const productFeedStyles = (theme) => ({
  root: {
    display: "flex",
    paddingBottom: 20,
  },
  sideBarContainerMobile: {
    height: "100%",
    position: "fixed",
    overflow: "auto",
    zIndex: theme.zIndex[0],
  },
  sideBarOpenOnMobile: {
    minHeight: '100vh',
    height: "100%",
    zIndex: theme.zIndex[10],
  },
  sideBarContainer: {
    minWidth: "300px",
    minHeight: "300px",
    height: "100%",
  },
  page: {
    marginLeft: 15,
    width: "100%",
  },
  pageMobile: {
    margin: "auto",
    width: "100%",
  },
  pagination: {
    margin: "auto",
  },
  buttonListMobile: {
    marginLeft: theme.spacing(2),
  },
  wrappers: {
    height: "100%",
  },
});

export const useProductFeedStyles = makeStyles(productFeedStyles);
