import { makeStyles } from "@material-ui/styles";

const buttonListStyles = theme => ({
    root: {
        display: 'flex',
        paddingBottom: 20,
        zIndex: theme.zIndex[5],
    },
    selected: {
      width: 200,
      padding: 0,
      border: `2px solid ${theme.palette.grey[300]}`,
      borderRadius: 10,
      '& span': {
          marginLeft: 10,
      },
      '&:hover': {
          border: `2px solid ${theme.palette.misis}`,
      },
      transition: 'border 0.2s',
    },
    selectedOnSelect: {
        border: `2px solid ${theme.palette.misis}`,
    },
    button: {
        width: '100%',
        textAlign: 'left',
        justifyContent: 'left', 
        textTransform: 'initial',
        marginLeft: 'auto',
    },
    list: {
        width: '200px',
        marginTop: 5,
        position: 'absolute',
        zIndex: theme.zIndex[5],
    },
});

export const useButtonListStyles = makeStyles(buttonListStyles);