import React, {useContext} from "react";
import { Link, useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { ProductCard } from "../product-card/vertical/ProductCard";
import { InfoText } from "../InfoText";
import { isFavPage, AppContext } from "../../common";
import {useProductPageMobileStyles, useProductPageStyles} from "./productPageStyles";

export const ProductsPage = ({goods, preview, empty}) => {
    const history = useHistory();
    const {isPhone} = useContext(AppContext);
    const simpleClasses = useProductPageStyles();
    const mobileClasses = useProductPageMobileStyles();
    const classes = isPhone ? mobileClasses : simpleClasses;

    const fillRows = () => {
        let cards = [];
        const items = preview ? new Array(4) : goods;
        for(let i = 0; i < items.length; i++) {
            cards.push(
              <div key={'div' + (goods[i]?.id || i)}>
                <ProductCard data={goods[i]} key={goods[i]?.id || i} preview={preview}/>
              </div>);
        }
        return cards;
    };

    return(
      <div className={classes.root} style={preview ? {gap: '10px'} : {}}>
        {(!preview && empty && !history.location.search && isFavPage(history)) 
        ? <InfoText text={"У вас ещё нет избранных объявлений."} style={{marginTop: 16}}/>
        : !preview && empty &&
          <Typography>
            По вашим параметрам ничего не найдено. Попробуйте изменить поисковой запрос или
          <Link to={isFavPage(history) ? '/fav/1' : '/page/1'} style={{textDecoration: 'none'}}> сбросить </Link>фильтры.
          </Typography>
        }
        {fillRows()}
      </div>
    );
}