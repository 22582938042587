import {makeStyles} from "@material-ui/core/styles";

const userInfoViewStyles = theme => ({
  section: {
    padding: theme.spacing(1), 
    display: 'inline-block', 
    width: '45%',
  },
  visibleIcon: {
    display: 'inline-block',
    fontSize: 16,
    marginRight: 3,
  },
});

const userInfoMobileViewStyles = theme => ({
  section: {
    padding: theme.spacing(1), 
    display: 'flex', 
  },
  visibleIcon: {
    display: 'inline-block',
    fontSize: 16,
    marginRight: 3,
    marginTop: 28,
  },
});

export const useUserInfoViewStyles = makeStyles(userInfoViewStyles);
export const useUserInfoMobileViewStyles = makeStyles(userInfoMobileViewStyles);