import React from "react";
import { Link } from '@material-ui/core';
import { Typography, useTheme } from "@material-ui/core";

export function Copyright() {
    const theme = useTheme();

    return (
      <>
      <Typography variant="body2" color="textSecondary" align="center">
        Авторизуясь, Вы принимаете&nbsp;<br/>
        <Link
          href="https://drive.google.com/file/d/1oX-h_t2dOolDhWz6ig9pUJhReZvq4_K4/view"
          target="_blank"
          style={{color: theme.palette.misis}}
        >
            Условия использования
        </Link>&nbsp;
      </Typography>
      </>
    );
}