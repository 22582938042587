import React, {useContext, useState} from "react";
import {Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {AppContext} from "../common";

export const useMessages = () => {
  const context = useContext(AppContext);

  return ({
    showError: (text) => context.openMessage("error", text),
    showSuccess: (text) => context.openMessage("success", text),
    showWarning: (text) => context.openMessage("warning", text),
    showInfo: (text) => context.openMessage("info", text),
  })
};

export const Message = () => {
  const context = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("error");
  const [text, setText] = useState("");

  const handleOpen = (type, text) => {
    setType(type);
    setText(text);
    setOpen(true);
  }

  const handleClose = () => setOpen(false);

  context.openMessage = handleOpen;

  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      style={{zIndex: "100"}}
      anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
    >
      <Alert onClose={handleClose} severity={type} variant="filled">
        {text}
      </Alert>
    </Snackbar>
  );
}