import {makeStyles} from "@material-ui/core";

const ModerationRequestStyles = theme => ({
  root: {
    minWidth: 1000,
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    marginBottom: theme.spacing(2),
  },
  sectionTitle: {
    marginLeft: theme.spacing(2),
  },
  goodPreview: {
    '&:first-child': {
      width: '100%',
    },
    marginTop: theme.spacing(2),
  },
  feedback: {
    marginTop: theme.spacing(2),
  },
  feedbackContent: {
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  actions: {
    width: 'min-content',
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
  defaultFeedback: {
    '& li': {
      width: 300,
    },
    '& #list': {
      width: 300
    }
  }
});

const ModerationRequestMobileStyles = theme => ({
  ...ModerationRequestStyles(theme),
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
  },
  defaultFeedback: {
    '& ul': {
      width: '100%'
    },
    '& li': {
      width: '100%',
    },
    '& #list': {
      width: '100%'
    }
  }
});

export const useModerationRequestStyles = makeStyles(ModerationRequestStyles, {name: "ModerationRequestStyles"});
export const useModerationRequestMobileStyles = makeStyles(ModerationRequestMobileStyles, {name: "ModerationRequestMobileStyles"});