import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import {Typography, Paper, Link} from "@material-ui/core";
import { EmailOutlined, Phone, Telegram, Room, Apartment, Favorite, FavoriteBorder, DeleteForever, Edit } from "@material-ui/icons";
import { UserAvatar } from "../../../components/UserAvatar";
import { AlertDialog } from "../../../components/AlertDialog";
import { useOwnerCardStyles, useOwnerCardMobileStyles } from "./ownerCardStyles";
import { StyledButton } from "../../../components/StyledButton";
import {TypographyPreviewfy} from "../../../components/TypographyPreviewfy";
import {AppContext, getFullName, phoneFormat} from "../../../common";
import {ADMIN} from "../../../secure";

export const OwnerCard = ({
  ownerInfo,
  role,
  price,
  defaultIsFavorite,
  handleClickFavorite,
  handleClickDelete,
  preview,
  id,
  isOwner,
  disableAll
}) => {
  const defaultClasses = useOwnerCardStyles();
  const mobileClasses = useOwnerCardMobileStyles();
  const context = useContext(AppContext);
  const classes = context.isPhone ? mobileClasses : defaultClasses;
  const history = useHistory();
  const [isFavorite, setIsFavorite] = useState(defaultIsFavorite);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleFavorite = () =>
    handleClickFavorite()
    .then(() => setIsFavorite(!isFavorite))
    .catch(() => setOpenDialog(true));

  useEffect(() => setIsFavorite(defaultIsFavorite), [defaultIsFavorite]);

  return (
    <Paper className={classes.ownerInfo}>
      <TypographyPreviewfy
        variant="h4"
        className={classes.price}
        text={price + ' ₽'}
        preview={preview}
        width={200}
        height={40}
      />
      <a href={ownerInfo?.vk_link} target="_blank" className={!!ownerInfo?.vk_link && classes.link}>
        <div className={classes.ownerInfoHeader}>
          <UserAvatar
            userNameSurname={getFullName(ownerInfo?.user_name, ownerInfo?.user_surname)}
            source={ownerInfo?.vk_picture_link}
            preview={preview}
          />
          <TypographyPreviewfy
            variant="subtitle1"
            className={!!ownerInfo?.vk_link && classes.linkText}
            text={getFullName(ownerInfo?.user_name, ownerInfo?.user_surname)}
            preview={preview}
            width={155}
          />
        </div>
      </a>
      <div className={classes.contactInfo}>
        {Object.keys(DATA_DESCRIPTION).map((e) => {
          let data = preview ? new Array(5) : ownerInfo[DATA_DESCRIPTION[e].fieldName];
          const format = DATA_DESCRIPTION[e].format;

          return !!data && (
            <div className={classes.contactInfoRow}>
              {preview
                ?
                <TypographyPreviewfy preview={preview} width={200}/>
                :
                <>
                  {DATA_DESCRIPTION[e].icon}
                  <Typography variant="subtitle1" color="textSecondary">
                    {e}:&nbsp;
                  </Typography>
                  {DATA_DESCRIPTION[e].fieldName === 'telegram_nick'
                    ? <Link href={`https://t.me/${data}`} target="_blank">{format(data)}</Link>
                    : <Typography>{!!format ? format(data) : data}</Typography>
                  }
                </>
              }
            </div>
          )})}
      </div>
      <StyledButton
        className={classes.favorite}
        text="В избранное"
        handleClick={handleFavorite}
        disabled={preview || disableAll}
        icon={isFavorite ? <Favorite/> : <FavoriteBorder/>}
      />
      {isOwner && <>
      <StyledButton
        className={classes.actionButton}
        text="Редактировать"
        handleClick={() => history.push(`/edit/${id}`)}
        variant="outlined"
        disabled={preview || disableAll}
        icon={<Edit/>}
      /></>}
      {(isOwner || role === ADMIN) && <>
        <StyledButton
        className={classes.actionButton}
        text="Удалить"
        handleClick={() => setOpenDeleteDialog(true)}
        variant="outlined"
        disabled={preview || disableAll}
        icon={<DeleteForever/>}
      /></>}
      {openDialog &&
      <AlertDialog
        title="Авторизация"
        text="Это действие доступно только авторизованным пользователям."
        confirmText="Хотите авторизоваться?"
        buttonText1="Да"
        buttonText2="Нет"
        handleClose={() => setOpenDialog(false)}
        handleAction={() => history.push('/signin?target_url=/page/1')}
      />}
      {openDeleteDialog && (
        <AlertDialog
          title="Удалить объявление"
          text="Объявление будет безвозвратно удалено."
          confirmText="Вы уверены?"
          buttonText1="Удалить"
          buttonText2="Отмена"
          handleClose={() => setOpenDeleteDialog(false)}
          handleAction={handleClickDelete}
        />
      )}
    </Paper>
  );
};

const DATA_DESCRIPTION = {
  Телефон: {
    fieldName: "phone_number",
    icon: <Phone />,
    format: value => phoneFormat(value)
  },
  Почта: {
    fieldName: "email",
    icon: <EmailOutlined />,
  },
  Telegram: {
    fieldName: "telegram_nick",
    icon: <Telegram />,
    format: value => `@${value}`
  },
  Общежитие: {
    fieldName: "dormitory_name",
    icon: <Apartment />,
  },
  Комната: {
    fieldName: "room_number",
    icon: <Room />,
  },
};
