import { makeStyles } from "@material-ui/core/styles";
import {commonStyles} from "../../commonStyles";

const profileStyles = (theme) => ({
  ...commonStyles(theme),
  root: {
    display: 'flex',
    minWidth: 800,
    width: '50%',
    margin: 'auto',
    flexDirection: 'column',
  },
  paper: {
    padding: "1rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
  },
  userView: {
    display: "flex",
    margin: theme.spacing(1),
    flexDirection: "column",
    alignItems: "center",
    width: "30%",
  },
  avatar: {
    width: "85px",
    height: "85px",
  },
  userInfo: {
    display: "flex",
    flexDirection: "row",
    width: '100%',
    flexWrap: "wrap",
  },
  paperHeaderText: {
    marginLeft: "1rem",
    fontWeight: "600",
  },
  paperHeader: {
    display: "flex",
    margin: theme.spacing(1),
  },
  name: {
    width: 'max-content',
  },
});

const profileMobileStyles = (theme) => ({
  title: {
    ...commonStyles(theme).title,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  root: {
    display: 'flex',
    paddingBottom: theme.spacing(2),
    flexDirection: 'column',
  },
  paper: {
    padding: "1rem",
    margin: `0 ${theme.spacing(1)}px`,
    display: "flex",
    alignItems: 'center',
    flexDirection: "column",
    justifyContent: "left",
  },
  userView: {
    display: "flex",
    margin: theme.spacing(1),
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    width: "85px",
    height: "85px",
  },
  personalInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  userInfo: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
  },
  paperHeaderText: {
    marginLeft: theme.spacing(1),
    fontWeight: "600",
  },
  paperHeader: {
    display: "flex",
    width: "100%",
    paddingRight: theme.spacing(5),
    justifyContent: 'center',
  },
});

export const useProfileStyles = makeStyles(profileStyles);
export const useProfileMobileStyles = makeStyles(profileMobileStyles);
