import {
  BASENAME,
  commonHeaders,
  fillUrlParamsForRequest,
  cardsPerPage,
  getAuthHeader,
  errorHandler,
} from "../common";

export const getGoods = (page, only_fav, history) => {
  let url = new URL("get_goods", BASENAME);
  let params = url.searchParams;
  params.set("page", page);
  params.set("count", cardsPerPage);
  params.set("only_fav", only_fav);
  fillUrlParamsForRequest(params);

  return fetch(url.href, {
    method: "GET",
    ...getAuthHeader(),
  })
    .then((response) => errorHandler(response, history))
    .then((response) => response.json());
};

export const getCategories = () =>
  fetch(`${BASENAME}get_subcategories`, {
    method: "GET",
    headers: {...commonHeaders},
    cache: "force-cache",
  })
    .then((response) => errorHandler(response))
    .then((response) => response.json());

export const getMinMaxPrice = (history, only_fav = false) => {
  let url = new URL("get_min_max", BASENAME);
  let params = url.searchParams;
  params.set("only_fav", only_fav);
  fillUrlParamsForRequest(params);

  return fetch(url.href, {
    method: "GET",
    ...getAuthHeader(),
  })
    .then((response) => errorHandler(response, history))
    .then((response) => response.json());
};

export const getGoodsCount = (history, isFav = false) => {
  let url = new URL("count_goods", BASENAME);
  let params = url.searchParams;
  params.set("only_fav", isFav);
  fillUrlParamsForRequest(params);

  return fetch(url.href, {
    method: "GET",
    ...getAuthHeader(),
  })
    .then((response) => errorHandler(response, history))
    .then((response) => response.json());
};

export const getDormitories = () =>
  fetch(`${BASENAME}get_dormitories`, {
    method: "GET",
    headers: {...commonHeaders},
    cache: "force-cache",
  })
    .then((response) => errorHandler(response))
    .then((response) => response.json());

export const getGoodByID = (history, id, is_request = false) =>
  fetch(`${BASENAME}get_good_by_id?good_id=${id}&is_request=${is_request}`, {
    method: "GET",
    ...getAuthHeader(),
  })
    .then((response) => errorHandler(response, history))
    .then((response) => response.json());

export const changeFavorite = (good_id) =>
  fetch(`${BASENAME}change_favourite`, {
    method: "POST",
    ...getAuthHeader(),
    body: JSON.stringify({good_id: good_id}),
  })
    .then((response) => errorHandler(response))
    .then((response) => response.json());

export const getMyGoods = (history) => {
  let url = new URL("get_goods_created_by_user", BASENAME);
  let params = url.searchParams;
  fillUrlParamsForRequest(params);

  return fetch(url.href, {
    method: "GET",
    ...getAuthHeader(),
  })
    .then((response) => errorHandler(response, history))
    .then((response) => response.json());
};

export const deleteGood = (good_id, history) =>
  fetch(`${BASENAME}delete_good?good_id=${good_id}`, {
    method: "DELETE",
    ...getAuthHeader(),
  })
    .then((response) => errorHandler(response, history))
    .then((response) => response.json());

export const getUserInfo = (user_id) =>
  fetch(`${BASENAME}get_user_info?user_id=${user_id}`, {
    method: "GET",
    headers: {...commonHeaders},
  })
    .then((response) => errorHandler(response))
    .then((response) => response.json());

export const createGood = (values, history) =>
  fetch(`${BASENAME}create_good`, {
    method: "POST",
    ...getAuthHeader(false),
    body: values,
  })
    .then((response) => errorHandler(response, history))
    .then((response) => response.json());

export const updateGood = (values, history) =>
  fetch(`${BASENAME}update_good`, {
    method: "PUT",
    ...getAuthHeader(false),
    body: values,
  })
    .then((response) => errorHandler(response, history))
    .then(response => response.json());

export const getUserGoodsCount = (history) =>
  fetch(`${BASENAME}count_user_goods`, {
    method: "GET",
    ...getAuthHeader(),
  })
    .then(response => errorHandler(response, history))
    .then(response => response.json());

export const getUserDormitory = (history) =>
  fetch(`${BASENAME}get_user_dormitory`, {
    method: "GET",
    ...getAuthHeader(),
  })
    .then(response => errorHandler(response, history))
    .then(response => response.json());