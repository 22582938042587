import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { InputBase, IconButton } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { updateURL, getValue, getCurrentUrlParams, isFavPage } from "../../common";

const getParamValue = () => getValue(getCurrentUrlParams().query, '');

export const SearchInput = ({id, classes}) => {
    const [query, setQuery] = useState(getParamValue());
    const history = useHistory();

    const handleSearch = (event) => updateURL(history, {query: query}, isFavPage(history) ? "/fav/1" : "/page/1");

    useEffect(() => {
        let textField = document.getElementById(id);
        const handleEnter = (event) => event.key === 13 && handleSearch(event);
        textField.addEventListener('keydown', handleEnter);
        return () => textField.removeEventListener('keydown', handleEnter);
    }, []);

    useEffect(() => setQuery(getParamValue()), [history.location.search]);

    useEffect(() => setQuery(''), [history.location]);

    return (
        <div className={classes.search}>
            <IconButton
                className={classes.searchIconButton}
                size='small'
                children={<Search className={classes.searchIcon}/>}
                onClick={handleSearch}
            />
            <InputBase
                id={id}
                placeholder="Найти..."
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                value={query}
                onChange={event => setQuery(event.target.value)}
                onKeyPress={event => event.key === 'Enter' && handleSearch(event)}
            />
        </div>
    );
}