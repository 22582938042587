import { makeStyles } from "@material-ui/core/styles";

const navigationBarMobileStyles = (theme) => ({
  grow: {
    flexGrow: 1,
    position: "sticky",
    top: 0,
    zIndex: theme.zIndex[15],
  },
  appBar: {
    position: "static",
    backgroundColor: "#27b4f6",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    color: "white",
    fontWeight: "bold",
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    zIndex: theme.zIndex[10],
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  body: {
    margin: 0,
    overflow: "hidden",
  },
  menu: {
    zIndex: theme.zIndex[15],
  },
});

const navigationBarStyles = (theme) => ({
  grow: {
    flexGrow: 1,
    position: "sticky",
    top: 0,
    zIndex: theme.zIndex[15],
  },
  root: {
    backgroundColor: theme.palette.common.white,
    position: "static",
    borderBottom: `2px solid ${theme.palette.grey[300]}`,
  },
  logoTitle: {
    textDecoration: "none",
    display: 'flex',
    alignItems: 'center',
  },
  actionsContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'end',
    marginLeft: '30px',
  },
  logo: {
    marginRight: 10,
  },
  title: {
    color: theme.palette.misis,
    fontWeight: "bold",
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  avatar: {
    width: 35,
    height: 35,
  }
});

export const useNavigationBarStyles = makeStyles(navigationBarStyles);
export const useNavigationBarMobileStyles = makeStyles(
  navigationBarMobileStyles
);
