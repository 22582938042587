import {makeStyles} from "@material-ui/core/styles";

const imageViewStyles = theme => ({
  container: {
    display: 'flex', 
    flexDirection: 'column',
  },
  imageWrapper: {
    height: 478,
    position: 'relative',
  },
  imageCover: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'center',
    backgroundPositionY: 'center',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: 'rgb(185 182 182 / 30%)',
    cursor: 'pointer',
  },
  dialogImage: {
    maxWidth: '100%',
    maxHeight: '80vh',
  },
  mainPhoto: {
    width: 'auto',
    height: '100%',
  },
  listRoot: {
    marginTop: 10,
    backgroundColor: theme.palette.background.paper,
  },
  list: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
    paddingBottom: 3,
  },
  sideSlider: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    cursor: 'pointer',
    zIndex: theme.zIndex[1],
    width: 50,
    height: '100%',
    top: 0,
    opacity: 0.5,
    '&:hover': {
      backgroundColor: theme.palette.grey[400],
    },
    transition: 'all 0.2s',
    '& svg': {
      color: 'white',
    },
  },
  openedImage: {
    padding: '5px 5px 3px 5px',
  },
});

const imageViewMobileStyles = theme => ({
  ...imageViewStyles(theme),
  imageWrapper: {
    height: 378,
    position: 'relative',
  },
});

export const useImageViewStyles = makeStyles(imageViewStyles);
export const useImageViewMobileStyles = makeStyles(imageViewMobileStyles);