import React, {useState, useEffect, useMemo} from "react";
import {useHistory} from "react-router-dom";
import {handleChooseSortItem, isFavPage} from "../../common";
import {ProductFeedView} from "./ProductFeedView";
import {getCategories, getDormitories, getGoods, getMinMaxPrice} from "../../api/productAPI";
import {useParams} from "react-router-dom";
import {useMessages} from "../../components/messages";

export const ProductFeed = () => {
  const history = useHistory();
  const [goods, setGoods] = useState([]);
  const [categories, setCategories] = useState(null);
  const [dormitories, setDormitory] = useState(null);
  const [minMax, setMinMax] = useState(null);
  const [preview, setPreview] = useState(true);
  const [sideBarPreview, setSideVarPreview] = useState(true);
  const [empty, setEmpty] = useState(false);
  const {showError} = useMessages();
  const {page} = useParams();
  const isFav = useMemo(() => isFavPage(history), [history]);

  const handleChooseSort = value => handleChooseSortItem(history, value);

  useEffect(() => {
    setPreview(true);
    getGoods(page, isFav, history)
      .then(response => {
        if (response.length === 0) {
          setEmpty(true);
        } else {
          setEmpty(false);
        }
        setGoods(response);
      })
      .catch(error => showError(error))
      .finally(() => setPreview(false));
  }, [history.location, page]);

  useEffect(() => {
    getCategories()
      .then((response) => setCategories(response))
      .catch(error => showError(error));
  }, []);

  useEffect(() => {
    getMinMaxPrice(history, isFav)
      .then((response) => setMinMax(response))
      .catch(error => showError(error));
  }, [history.location]);

  useEffect(() => {
    getDormitories()
      .then((response) => setDormitory(response.filter(e => e.id !== 0)))
      .catch(error => showError(error));
  }, []);

  useEffect(() => {
    !!categories && !!dormitories && !!minMax &&
    setSideVarPreview(false)
  }, [categories, dormitories, minMax]);

  return (
    <ProductFeedView
      goods={goods}
      categories={categories}
      dormitories={dormitories}
      minMax={minMax}
      handleChooseSortItem={handleChooseSort}
      preview={preview}
      sideBarPreview={sideBarPreview}
      empty={empty}
      isFavorite={isFav}
    />
  );
};