import React, {useState, useContext, useEffect} from "react";
import { Link, useHistory } from "react-router-dom";
import {AppBar, Toolbar, Typography, Menu, MenuItem, IconButton, useMediaQuery} from "@material-ui/core";
import { AccountCircle, AllInbox } from "@material-ui/icons";
import FavoriteIcon from "@material-ui/icons/Favorite";
import MenuIcon from "@material-ui/icons/Menu";
import Close from "@material-ui/icons/Close";
import MoreIcon from "@material-ui/icons/MoreVert";
import {ThumbsUpDown} from "@material-ui/icons";
import { SearchInput } from "../search-input/SearchInput";
import { UserAvatar } from "../UserAvatar";
import { TextIconButton } from "../text-icon-button/TextIconButton";
import { useTheme } from "@material-ui/core/styles";
import { useNavigationBarStyles, useNavigationBarMobileStyles } from "./navigationBarStyles";
import { useSearchMobileStyles, useSearchStyles } from "../search-input/SearchStyles";
import logo from "./logo.png";
import { AppContext, ids } from "../../common";
import {Secure} from "../Secure";
import {ADMIN, MODERATOR} from "../../secure";

export const NavigationBarView = (props) => {
  const { isPhone } = useContext(AppContext);

  return isPhone ? (
    <NavigationBarMobileView {...props} />
  ) : (
    <NavigationBarSimpleView {...props} />
  );
};

const NavigationBarSimpleView = (props) => {
  const classes = useNavigationBarStyles();
  const searchClasses = useSearchStyles();
  const { id, handlers, title, userInfo } = props;
  const theme = useTheme();
  const titleIsVisible = useMediaQuery('(min-width: 1000px)');
  const profileIconText = userInfo.user_name || "Профиль";

  const profileIcon = !!userInfo.user_name 
    ? <UserAvatar 
        source={userInfo.vk_picture_link} 
        userNameSurname={`${userInfo.user_name} ${userInfo.user_surname}`} 
        className={classes.avatar}
      />
    : <AccountCircle fontSize="large" />;

  return (
    <div className={classes.grow} id={id}>
      <AppBar className={classes.root} elevation={0}>
        <Toolbar>
          <Link to="/page/1" className={classes.logoTitle}>
          <img src={logo} width={50} className={classes.logo} alt={"Logo"} />
            {titleIsVisible &&
              <Typography className={classes.title} variant="h6" noWrap>
                {title}
              </Typography>
            }
          </Link>
          <SearchInput
            id={ids.queryId}
            classes={searchClasses}
          />
          <div className={classes.actionsContainer}>
            <Secure roles={[MODERATOR, ADMIN]}>
              <TextIconButton
                icon={<ThumbsUpDown fontSize="large"/>}
                text="Модерация"
                firstColor={theme.palette.misis}
                secondColor={theme.palette.misisLight}
                onClick={handlers[4]}
              />
            </Secure>
            <TextIconButton
              icon={<AllInbox fontSize="large" />}
              text="Мои объявления"
              firstColor={theme.palette.misis}
              secondColor={theme.palette.misisLight}
              onClick={handlers[0]}
            />
            <TextIconButton
              icon={<FavoriteIcon fontSize="large" />}
              text="Избранное"
              firstColor={theme.palette.misis}
              secondColor={theme.palette.misisLight}
              onClick={handlers[1]}
            />
            <TextIconButton
              icon={profileIcon}
              text={profileIconText}
              firstColor={theme.palette.misis}
              secondColor={theme.palette.misisLight}
              onClick={handlers[2]}
            />
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

const NavigationBarMobileView = (props) => {
  const { id, handlers } = props;
  const classes = useNavigationBarMobileStyles();
  const searchClasses = useSearchMobileStyles();
  const context = useContext(AppContext);
  const history = useHistory();

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [sideBarIsOpen, setSideBarIsOpen] = useState(false);
  const [currentPathname, setCurrentPathname] = useState(history.location.pathname.split('/')[1]);
  const mobileMenuId = "primary-search-account-menu-mobile";
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleClickSideBar = (isOpen) => {
    context.sideBarCallback(isOpen);
    setSideBarIsOpen(isOpen);
  };

  context.handleClickSideBar = handleClickSideBar;

  useEffect(() => setCurrentPathname(history.location.pathname.split('/')[1]),[history.location]);

  const handleClickMenuItem = handler => {
    setMobileMoreAnchorEl(null);
    handler();
  };

  const renderMobileMenu = (
    <Menu
      className={classes.menu}
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={() => setMobileMoreAnchorEl(null)}
    >
      <MenuItem onClick={() => handleClickMenuItem(handlers[0])}>Мои объявления</MenuItem>
      <MenuItem onClick={() => handleClickMenuItem(handlers[1])}>Избранное</MenuItem>
      <MenuItem onClick={() => handleClickMenuItem(handlers[2])}>Профиль</MenuItem>
      <Secure roles={[MODERATOR, ADMIN]}>
        <MenuItem onClick={() => handleClickMenuItem(handlers[4])}>Модерация</MenuItem>
      </Secure>
      <MenuItem onClick={() => handleClickMenuItem(handlers[3])}>Главная</MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow} id={id}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          {(currentPathname === "page" || currentPathname === "fav") &&
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              onClick={() => handleClickSideBar(!sideBarIsOpen)}
            >
              {sideBarIsOpen ? <Close/> : <MenuIcon/>}
            </IconButton>
          }
          <SearchInput
            id={ids.queryId}
            classes={searchClasses}
          />
          <div className={classes.grow} />
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={(e) => setMobileMoreAnchorEl(e.currentTarget)}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </div>
  );
};
