import React, { useContext } from "react";
import {Skeleton} from "@material-ui/lab";
import { Tooltip, Typography } from "@material-ui/core";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { DATA_DESCRIPTION } from "../profileUtils";
import { isTrueObject, phoneFormat, AppContext } from "../../../common";
import { useUserInfoViewStyles, useUserInfoMobileViewStyles } from "./userInfoViewStyles";

export const UserInfoView = ({userInfo, preview}) =>
   Object.keys(DATA_DESCRIPTION).map(e => {
    const fieldName = DATA_DESCRIPTION[e].fieldName;
    let data = null;

    if(!preview) {
       data = userInfo[fieldName];

      if (fieldName === 'phone_number') {
        data = isTrueObject(data) ? phoneFormat(data) : undefined;
      } else if (fieldName === 'telegram_nick') {
        data = isTrueObject(data) ? "@" + data : undefined;
      }
    }

    return (
      <ProfileSection
        title={e}
        content={isTrueObject(data) ? data : "Не указано"}
        visible={preview ? null : userInfo[DATA_DESCRIPTION[e].visibleName]}
        preview={preview}
      />
    );
  });

const ProfileSection = ({visible, title, content, preview}) => {
  const { isPhone } = useContext(AppContext);
  const mobileClasses = useUserInfoMobileViewStyles();
  const defaultClasses = useUserInfoViewStyles();

  const classes = isPhone ? mobileClasses : defaultClasses;
  const visibleState = visible === null || visible === false ? 2 : visible !== undefined ? 1 : 0;
  const tooltipTitle = visibleState === 2 ? 'Не видно пользователям' : visibleState === 1 && 'Видно пользователям';
  //2 - закрытый глаз, 1 - открытый глаз, 0 - невидимый
  return(preview
    ? <div className={classes.section}>
        <Skeleton width={100} height={40}/>
      </div>
    :
    <div className={classes.section}>
      {visibleState ? 
      <Tooltip title={tooltipTitle} placement="bottom">
        {visibleState === 2 
        ? <VisibilityOffIcon className={classes.visibleIcon}/> 
        : visibleState === 1 
          ? <VisibilityIcon className={classes.visibleIcon}/>
          : <></>
        }
        </Tooltip>
      : 
      <VisibilityOffIcon className={classes.visibleIcon} style={{opacity: 0}}/>}
      <div style={{display: 'inline-block'}}>
        <span style={{color: 'gray'}}>{title}</span>
        <Typography variant='h6'>{content}</Typography>
        </div>
    </div>
  );
};