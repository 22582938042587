import React from "react";
import { Select, FormControl, InputLabel, MenuItem, FormHelperText } from "@material-ui/core";

export const SelectList = ({
  label,
  items,
  rootClass,
  handleChange,
  name,
  value,
  disabled,
  error,
  helperText,
}) => {
  const values = !!items ? items : [];

  return (
    <FormControl className={rootClass}>
      <InputLabel id={`select${name}Label`} error={error}>{label}</InputLabel>
      <Select
        style={{width: '100%'}}
        name={name}
        defaultValue={0}
        MenuProps={menuProps}
        value={value}
        labelId={`select${name}Label`}
        disabled={disabled}
        onChange={handleChange}
        error={error}
      >
        {values.map((e) => (
          <MenuItem value={e.id} key={name + e.id}>
            {e.name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export const menuProps = {
  PaperProps: {
    style: {
      maxHeight: 200,
      width: 250,
    }
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null
};