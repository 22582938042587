import { makeStyles } from "@material-ui/styles";

const myAdvertisementsStyles = (theme) => ({
  root: {
    width: '100%',
    marginLeft: theme.rootLeftMargin,
    paddingBottom: 40,
  },
  head: {
    display: "flex",
    alignItems: "center",
    gap: '10px',
    marginTop: theme.spacing(1),
  },
  buttonList: {
    padding: 0,
  },
  cardBlock: {
    display: 'flex',
    flexDirection: 'row',
  },
  feedbackBlock: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 15,
    height: 220,
    width: '25%',
    marginLeft: theme.spacing(2),
    '& div': {
      marginLeft: theme.spacing(1),
    },
    '& h3': {
      color: theme.palette.misis,
    }
  }
});

const myAdvertisementsMobileStyles = (theme) => ({
  ...myAdvertisementsStyles(theme),
  root: {
    width: "100%",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: "auto",
    paddingBottom: 40,
  },
  buttonList: {
    padding: 0,
    marginLeft: theme.spacing(2),
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '10px',
    marginTop: theme.spacing(1),
  },
  scrollButton: {
    left: 'auto',
  },
});

export const useMyAdvertisementsStyles = makeStyles(myAdvertisementsStyles);
export const useMyAdvertisementsMobileStyles = makeStyles(myAdvertisementsMobileStyles);
