import React, {useState, useEffect } from "react";
import { Collapse, List, ListItem, ListItemText, Paper, Button } from "@material-ui/core";
import {ExpandLess, ExpandMore, Check } from '@material-ui/icons';
import { useButtonListStyles } from "./buttonListStyles";

const getValueByName = (values, name) => {
    return values.filter(e => e.name === name)[0];
};

export const ButtonList = ({className, style, values, id, initialValue, handleChooseItem}) => {
    const classes = useButtonListStyles();
    const [selectItem, setSelectItem] = useState(initialValue ? getValueByName(values, initialValue) : values[0]);
    const [isCollapse, setCollapse] = useState(false);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside)
    }, []);

    const handleItemClick = (ind) => {
        setSelectItem(values[ind]);
        setCollapse(false);
        handleChooseItem(values[ind].name);
    };

    const handleClickOutside = (event) => {
        const e1 = document.getElementById('selected');
        const e2 = document.getElementById('list');
        if(!(e1?.contains(event.target) || e2?.contains(event.target))) {
            setCollapse(false);
        }
    };
    
    return(
        <List className={`${className} ${classes.root}`} style={style}>
            <ListItem 
                id={'selected'}
                className={`${classes.selected} ${isCollapse && classes.selectedOnSelect}`} 
                onClick={() => setCollapse(!isCollapse)}
            >
                <ListItemText id={id} name={selectItem.name} primary={selectItem.value}/>
                {isCollapse ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Paper id={'list'} className={classes.list} elevation={5}>
                <Collapse in={isCollapse}>
                    {values.map((e, ind) => 
                        <Button 
                            id={ind}
                            className={classes.button} 
                            key={ind} 
                            variant="text"
                            onClick={() => handleItemClick(ind)}
                        >
                            {e.value}
                            {e === selectItem && <Check fontSize={'small'} style={{marginLeft: 'auto'}}/>}
                        </Button>
                    )}
                </Collapse>
            </Paper>
        </List>
    );
}