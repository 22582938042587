import React, {useContext} from "react";
import {useHistory} from "react-router-dom";
import InputMask from "react-input-mask";
import {useFormik} from "formik";
import * as yup from "yup";
import {IconButton, InputAdornment, TextField, Typography} from "@material-ui/core";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import {StyledButton} from "../../../components/StyledButton";
import {SelectList} from "../../../components/select-list/SelectList";
import {updateUserInfo} from "../../../api/accountAPI";
import {DATA_DESCRIPTION, getRequestObject, getIdByName} from "../profileUtils";
import {useUserInfoFormStyles, useUserInfoFormMobileStyles} from "./userInfoFormStyles";
import {AppContext} from "../../../common";
import {useMessages} from "../../../components/messages";

export const UserInfoForm = ({userInfo, dormitories, handleClose, callback}) => {
  const history = useHistory();
  const {showSuccess, showError} = useMessages();
  const {isPhone} = useContext(AppContext);
  const defaultClasses = useUserInfoFormStyles();
  const mobileClasses = useUserInfoFormMobileStyles();

  const classes = isPhone ? mobileClasses : defaultClasses;

  const save = () => {
    values.phone_number = unmaskedPhone(values.phone_number);
    return updateUserInfo(values, history)
      .then(() => {
        handleClose();
        callback();
        showSuccess("Информация обновлена!")
      })
      .catch(error => showError(error));
  };

  const {values, errors, handleSubmit, handleChange, isSubmitting, setFieldValue} = useFormik({
    initialValues: getRequestObject({
        ...userInfo,
        id_dormitory: getIdByName(dormitories, userInfo.dormitory_name)
      }
    ),
    onSubmit: () => save(),
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: VALIDATION_SCHEMA,
  });

  return (
    <>
      <Typography variant="h6">Редактирование</Typography>
      <form onSubmit={handleSubmit}>
        <div className={classes.root}>
          {Object.keys(DATA_DESCRIPTION).filter(e => DATA_DESCRIPTION[e].editable && e !== 'Общежитие').map(e => {
            const fieldName = DATA_DESCRIPTION[e].fieldName;
            const visibleName = DATA_DESCRIPTION[e].visibleName;
            return (
              <div className={classes.itemContainer}>
                <div>
                  {fieldName === "phone_number" ?
                    <PhoneTextField
                      InputProps={{
                        endAdornment:
                          <VisibleIcon
                            classes={classes.iconButton}
                            isSubmitting={isSubmitting}
                            values={values}
                            setFieldValue={setFieldValue}
                            visibleName={visibleName}
                          />
                      }}
                      name={fieldName}
                      className={classes.textField}
                      disabled={isSubmitting}
                      error={!!errors[fieldName]}
                      helperText={errors[fieldName]}
                      label={e}
                      value={values[fieldName]}
                      setFieldValue={setFieldValue}
                      variant="standard"
                    />
                    :
                    <TextField
                      InputProps={{
                        endAdornment:
                          <VisibleIcon
                            classes={classes.iconButton}
                            isSubmitting={isSubmitting}
                            values={values}
                            setFieldValue={setFieldValue}
                            visibleName={visibleName}
                          />,
                        startAdornment: fieldName === 'telegram_nick' && !!values[fieldName] && values[fieldName][0] !== "@" && "@"
                      }}
                      name={fieldName}
                      className={classes.textField}
                      placeholder={fieldName === 'telegram_nick' && "@"}
                      disabled={isSubmitting}
                      error={!!errors[fieldName]}
                      helperText={errors[fieldName]}
                      label={e}
                      value={values[fieldName]}
                      onChange={handleChange}
                      variant="standard"
                    />
                  }
                </div>
              </div>
            )
          })}
          <div className={classes.itemContainer}>
            <div>
              <SelectList
                label="Общежитие"
                rootClass={classes.textField}
                items={dormitories}
                name='id_dormitory'
                value={values.id_dormitory}
                disabled={isSubmitting}
                handleChange={handleChange}
              />
            </div>
          </div>
          <div className={classes.actions}>
            <StyledButton disabled={isSubmitting} text='Отмена' variant='outlined' handleClick={handleClose}/>
            <StyledButton disabled={isSubmitting} text='Сохранить' type='submit'/>
          </div>
        </div>
      </form>
    </>
  );
};

const VisibleIcon = ({className, isSubmitting, values, setFieldValue, visibleName}) =>
  <InputAdornment position="end">
    {visibleName ?
      <IconButton
        className={className}
        disabled={isSubmitting}
        size='small'
        name={visibleName}
        value={values[visibleName]}
        onClick={() => setFieldValue(visibleName, !values[visibleName])}
      >
        {values[visibleName] ? <VisibilityIcon/> : <VisibilityOffIcon/>}
      </IconButton>
      :
      <GhostIconButton className={className}/>
    }
  </InputAdornment>

const GhostIconButton = ({className}) =>
  <IconButton style={{opacity: 0}} className={className} size='small'>
    <VisibilityIcon/>
  </IconButton>

const PhoneTextField = ({disabled, setFieldValue, value, ...otherProps}) => {
  const onChange = (event) => {
    setFieldValue(otherProps.name, event.target.value);
  };

  return <InputMask
    mask="+7(999)-999-99-99"
    maskPlaceholder="_"
    disabled={disabled}
    onChange={onChange}
    value={value}
  >
    {() => <TextField {...otherProps} disabled={disabled}/>}
  </InputMask>
}

const unmaskedPhone = (phone) => !phone ? "" : phone?.length === 10 ? phone : phone?.substr(3, phone?.length).replace(/[-()_]/g, "");

const VALIDATION_SCHEMA = yup.object().shape({
  email: yup.string().notRequired().email("Некорректный email")
    .test("email", "Некорректный email", email => !email ? true : /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)),
  phone_number: yup.string().notRequired().test('len', "Некорректный номер телефона", value => !!value ? unmaskedPhone(value)?.length === 10 : true),
  room_number: yup.string().notRequired().max(6, 'Не должен превышать 6 символов')
    .test("room_number", "Может состоять из цифр от 0 до 9 и '-'", room => !room ? true : /^\d{1,4}-?\d$/.test(room)),
  telegram_nick: yup.string().notRequired().max(32, 'Не должен превышать 32 символа')
    .test('at', 'Не может быть больше одного символа "@"', telegram => !!telegram ? !telegram.includes('@') : true)
    .test("telegram", "Не может включать буквы кириллицы", telegram => !telegram ? true : !/[а-яА-ЯёЁ]/.test(telegram)),
});