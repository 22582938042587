import React, { useEffect, useContext } from "react";
import { Route, useHistory } from "react-router-dom";
import { signIn } from "../api/accountAPI";
import {AppContext} from "../common";

export const BaseRoute = ({ component: Component, ...props }) => {
  const history = useHistory();
  const context = useContext(AppContext);

  useEffect(() => {
    const url = new URL(window.location.href);
    const vk_code = url.searchParams.get("code");
    for(let key of url.searchParams.keys()) {
      url.searchParams.delete(key);
    }

    context.updateUserInfo();
    !!vk_code &&
    signIn(vk_code, url.toString())
    .then(() => {
      history.replace(history.location.pathname);
    });
  }, []);

  window.scrollTo(0, 0);

  return <Route {...props} render={(props) => <Component {...props}/>} />;
};