import { makeStyles } from "@material-ui/core/styles";

const productCardStyles = (theme) => ({
  root: {
    display: "inline-block",
    width: "265px",
    textAlign: "left",
    borderRadius: "15px",
    border: "2px solid transparent",
    transition: "border 0.2s",
    zIndex: theme.zIndex[1],
  },
  cardHovered: {
    border: `2px solid ${theme.palette.misis}`,
  },
  media: {
    height: 0,
    borderRadius: "10px",
    width: "95%",
    margin: "auto",
    marginTop: "5px",
    paddingTop: 258,
    marginBottom: "10px",
  },
  defaultImage: {
    width: '95%',
    height: 258,
    margin: 'auto',
    marginBottom: "10px",
    marginTop: "5px",
    borderRadius: "10px",
  },
  content: {
    paddingBottom: 0,
    paddingTop: 0,
    width: "92%",
  },
  date: {
    marginLeft: "8px",
  },
  price: {
    paddingTop: theme.spacing(1),
    paddingBottom: 5,
  },
  bottomPart: {
    height: 0,
    paddingBottom: "25px",
    paddingTop: "6px",
  },
  name: {
    width: "225px",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    "-webkit-line-clamp": 2,
    height: 70,
  },
  heart: {
    color: "#f81a1a",
    fontSize: "30px",
  },
  category: {
    color: "rgba(0, 0, 0, 0.54)",
    marginRight: "5px",
    height: "20px",
  },
});

const productCardMobileStyles = (theme) => ({
  divContainer: {
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  root: {
    display: "inline-block",
    width: theme.width.card,
    textAlign: "left",
    zIndex: theme.zIndex[1],
    boxShadow: "none",
    borderRadius: 0,
  },
  media: {
    height: 0,
    width: "92%",
    margin: "auto",
    marginTop: "8px",
    paddingTop: 160,
  },
  defaultImage: {
    width: '92%',
    height: 160,
    margin: 'auto',
    marginTop: "8px",
  },
  content: {
    width: "auto",
    padding: '0px 4px 0px 6px',
  },
  date: {
    marginLeft: "4px",
    fontSize: 8,
  },
  price: {
    paddingTop: theme.spacing(1),
    paddingBottom: "5px",
  },
  bottomPart: {
    height: 0,
    padding: "6px 4px 15px 4px",
  },
  name: {
    width: "fit-content",
    height: 47,
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    "-webkit-line-clamp": 2,
  },
  heart: {
    color: "#f81a1a",
  },
  category: {
    color: "rgba(0, 0, 0, 0.54)",
    marginRight: "5px",
    fontSize: "x-small",
    height: 15,
  },
  dormitory: {
    fontSize: "x-small",
    height: 20,
  },
});

export const useProductCardMobileStyles = makeStyles(productCardMobileStyles);
export const useProductCardStyles = makeStyles(productCardStyles);
