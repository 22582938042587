import React, {useState, useContext} from "react";
import {Link, useHistory} from "react-router-dom";
import {Skeleton} from "@material-ui/lab";
import {Button, Card, CardMedia, Chip, Dialog, Paper, Typography, useTheme} from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import ApartmentIcon from "@material-ui/icons/Apartment";
import EditIcon from "@material-ui/icons/Edit";
import EventIcon from "@material-ui/icons/Event";
import InfoIcon from '@material-ui/icons/Info';
import {IconButtonTooltip} from "../../IconButtonTooltip";
import {AlertDialog} from "../../AlertDialog";
import {DefaultImage} from "../../DefaultImage";
import {useProductCardStyles, useProductCardMobileStyles} from "./productCardStyles";
import {AppContext, createCategoryViewName, STATUS} from "../../../common";
import {deleteGood} from "../../../api/productAPI";
import {useMessages} from "../../messages";

export const ProductCard = ({data, callback, preview}) => {
  const theme = useTheme();
  const history = useHistory();
  const {showSuccess, showError} = useMessages();
  const {isPhone} = useContext(AppContext);
  const defaultClasses = useProductCardStyles();
  const mobileClasses = useProductCardMobileStyles();
  const classes = isPhone ? mobileClasses : defaultClasses;

  const [openDialog, setOpenDialog] = useState(false);
  const [showComment, setShowComment] = useState(false);

  const handleCancelDelete = () => setOpenDialog(false);

  const handleDelete = () =>
    deleteGood(data.id, history)
      .then((response) => {
        response === 0 && callback(data.id);
        showSuccess("Объявление удалено");
      })
      .catch((error) => showError(error));

  const STATUS_COLOR = {
    awaiting: theme.palette.misisLightPurple,
    pending: theme.palette.misisOrange,
    amending: theme.palette.error.main,
    active: theme.palette.misisGreen,
    archive: '',
  };
  const isAmending = data.status === STATUS.AMENDING.value;

  return (preview
      ?
      <Skeleton
        variant='rect'
        width={isPhone ? '90%' : 830}
        height={isPhone ? 400 : 220}
        style={{marginTop: 15, borderRadius: 10}}
      />
      :
      <Card key={`card${data.id}`} className={classes.card} elevation={2}>
        <Link to={`/detail/${data.id}`} className={classes.link}>
          {data.images.length === 0
            ? <DefaultImage className={classes.defaultImage}/>
            : <CardMedia className={classes.media} image={data.images[0]}/>
          }
        </Link>
        <div className={classes.content}>
          <Link to={`/detail/${data.id}`} className={classes.link}>
            <div>
              <Typography
                className={classes.name}
                variant="h6"
                color="primary"
              >
                {data.good_name}
              </Typography>
              <ExtraInfo
                icon={<EventIcon/>}
                left="Дата публикации"
                right={data.date}
              />
              <ExtraInfo
                icon={<PlaylistAddCheckIcon/>}
                left="Категория"
                right={createCategoryViewName(data.category, data.subcategory)}
              />
              <ExtraInfo
                icon={<ApartmentIcon/>}
                left="Общежитие"
                right={data.dormitory}
              />
            </div>
          </Link>
          <div className={classes.price}>
            <Typography variant="h5" color="textPrimary">
              {data.price} ₽
            </Typography>
            <div className={classes.statusContainer}>
              <Chip
                className={classes.status}
                style={{backgroundColor: STATUS_COLOR[data.status], color: 'white'}}
                size="small"
                label={getStatusByValue(data.status)?.title}
              />
              {isAmending && isPhone &&
                <Button
                  className={classes.reasonButton}
                  onClick={() => setShowComment(true)}
                  size="small"
                  variant="outlined"
                >
                  Причина
                </Button>}
            </div>
          </div>
        </div>
        <div className={classes.actionContainer}>
          <IconButtonTooltip
            title="Удалить"
            className={`${classes.button} ${classes.buttonDelete}`}
            icon={<DeleteForeverIcon/>}
            onClick={() => setOpenDialog(true)}
          />
          <IconButtonTooltip
            title="Редактировать"
            className={`${classes.button} ${classes.buttonEdit}`}
            icon={<EditIcon/>}
            onClick={() => history.push(`edit/${data.id}`)}
          />
        </div>
        {openDialog && (
          <AlertDialog
            title="Удалить объявление"
            text="Объявление будет безвозвратно удалено."
            confirmText="Вы уверены?"
            buttonText1="Удалить"
            buttonText2="Отмена"
            handleClose={handleCancelDelete}
            handleAction={handleDelete}
          />
        )}
        <Dialog style={{zIndex: theme.zIndex[20]}} open={showComment} onClose={() => setShowComment(false)}>
          <Paper className={classes.moderatorComment}>
            <Typography variant="h5"><b>Комментарий модератора</b></Typography>
            <br/>
            <Typography variant="subtitle1">{data?.comment}</Typography>
          </Paper>
        </Dialog>
      </Card>
  );
};

const ExtraInfo = ({icon, left, right}) => (
  <div style={{display: "flex", alignItems: 'center'}}>
    <div style={{margin: '0.3rem'}}>{icon}</div>
    <div style={{display: "flex", alignItems: 'baseline'}}>
      <Typography color="textSecondary">
        {left}:&nbsp;
      </Typography>
      {right}
    </div>
  </div>
);

const getStatusByValue = (value) => Object.values(STATUS).find(s => s.value === value);