import { BASENAME, commonHeaders, getAuthHeader, errorHandler } from "../common";

export const signIn = (vk_code, redirect_uri) => 
  fetch(`${BASENAME}sign_in?code=${vk_code}&redirect_uri=${redirect_uri}`, {
      method: "GET",
      headers: {...commonHeaders},
  })
  .then(response => errorHandler(response))
  .then(response => response.json())
  .then(data => {
    localStorage.setItem('token', data.jwt);
    return data;
  }); //получается также поле is_new

export const checkToken = () => 
  fetch(`${BASENAME}check_token`, {
    method: "GET",
    ...getAuthHeader(),
  })
  .then(response => response.json());

export const getUserInfoAdv = (history) =>
  fetch(`${BASENAME}get_user_info_adv`, {
    method: "GET",
    ...getAuthHeader(),
  })
  .then(response => errorHandler(response, history))
  .then(response => response.json());

export const updateUserInfo = (data, history) =>
  fetch(`${BASENAME}update_user_info`, {
    method: "PUT",
    ...getAuthHeader(),
    body: JSON.stringify({...data}),
  })
  .then((response) => errorHandler(response, history))
  .then(response => response.json());

export const getUserInfoBrief = () => 
  fetch(`${BASENAME}get_user_info_brief`, {
    method: "GET",
    ...getAuthHeader(),
  })
  .then((response) => errorHandler(response))
  .then(response => response.json());