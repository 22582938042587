import {
  BASENAME,
  commonHeaders,
  fillUrlParamsForRequest,
  cardsPerPage,
  getAuthHeader,
  errorHandler,
} from "../common";

export const getModerationRequests = (sort_by = 'date_ask') =>
  fetch(`${BASENAME}moderator_get_goods?sort_by=${sort_by}`, {
    method: "GET",
    ...getAuthHeader(),
  })
    .then((response) => errorHandler(response))
    .then((response) => response.json());

export const sendModerationVerdict = (good_id, status, comment = "") => fetch(
  `${BASENAME}moderation_verdict`, {
    method: "POST",
    body: JSON.stringify({"good_id": +good_id, "status": status, "comment": comment}),
    ...getAuthHeader(),
  })
  .then((response) => errorHandler(response))
  .then((response) => response.json());