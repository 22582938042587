import { makeStyles } from "@material-ui/core/styles";

const ownerCardStyles = (theme) => ({
  preview: {
    display: 'flex',
    width: 250,
    padding: 10,
    height: 458,
    alignItems: 'center',
    justifyContent: 'center',
    '& :first-child': {
      color: theme.palette.misis,
    }
  },
  price: {
    marginTop: theme.spacing(1),
  },
  ownerInfo: {
    display: 'flex',
    flexDirection: 'column',
    width: 'max-content',
    padding: 10,
    height: 'max-content',
  },
  ownerInfoHeader: {
    display: 'flex',
    marginTop: 30,
    height: 'fit-content',
    alignItems: 'center',
    gap: 5,
  },
  contactInfo: {
    display: 'flex',
    margin: '20px 0',
    flexDirection: 'column',
  },
  contactInfoRow: {
    display: 'flex',
    margin: '5px 0',
    alignItems: 'center',
    flexDirection: 'row',
    '& svg': {
      paddingRight: 3,
    },
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.misis,
  },
  linkText: {
    '&:hover': {
      'text-decoration-line': 'underline',
    },
  },
  favorite: {
    marginTop: theme.spacing(2),
  },
  actionButton: {
    marginTop: theme.spacing(1),
  },
});

const ownerCardMobileStyles = (theme) => ({
  ...ownerCardStyles(theme),
  preview: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& :first-child': {
      color: theme.palette.misis,
    }
  },
  ownerInfo: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
  },
  ownerInfoHeader: {
    display: 'flex',
    marginTop: 20,
    flexGrow: 1,
    height: 'fit-content',
    alignItems: 'center',
    gap: 5,
  },
});

export const useOwnerCardStyles = makeStyles(ownerCardStyles);
export const useOwnerCardMobileStyles = makeStyles(ownerCardMobileStyles);