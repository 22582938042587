import { makeStyles } from "@material-ui/core/styles";

const notFoundStyles = (theme) => ({
  root: {
    width: 'max-content',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 'auto'
  },
  h1: {
    fontSize: '80pt',
    marginBottom: 0,
  },
  h3: {
    fontSize: '12pt',
    marginTop: 5,
  }
});

export const useNotFoundStyles = makeStyles(notFoundStyles, {"name": "notFoundStyles"});