import React from 'react';
import {StyledButton} from "../StyledButton";
import {useHistory} from "react-router-dom";
import {useNotFoundStyles} from "./notFoundStyles";

export const NotFound = () => {
  const history = useHistory();
  const classes = useNotFoundStyles();

  return (
    <div className={classes.root}>
      <h1 className={classes.h1}>404</h1>
      <h3 className={classes.h3}>страница не найдена</h3>
      <StyledButton
        size="large"
        text="На главную"
        handleClick={() => history.replace("/page/1")}
      />
    </div>
  );
};