import React, {useState, useEffect, useContext} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useFormik} from "formik";
import {useMessages} from "../../components/messages";
import * as yup from 'yup';
import {Typography, TextField, Divider, Link} from "@material-ui/core";
import NumberFormat from 'react-number-format';
import {MultiItemsSelector} from "./multi-items-selector/MultiItemsSelector";
import {ImageDragBox} from "./image-drag-box/ImageDragBox";
import {SelectList} from "../../components/select-list/SelectList";
import {StyledButton} from "../../components/StyledButton";
import {Preview} from "../../components/Preview";
import {useAddProductStyles, useAddProductMobileStyles} from "./addProductStyles";
import {
  getCategories,
  getDormitories,
  createGood,
  updateGood,
  getGoodByID,
  getUserGoodsCount,
  getUserDormitory
} from "../../api/productAPI";
import {
  DATA_DESCRIPTION,
  MESSAGES,
  isEquals,
  convertFiles,
  MAX_PRICE,
  getRemovedUrls,
  getAddedFiles
} from "./addProductUtils";
import {containsMat, validationMessage} from "../../antimat";
import {AppContext, goodsPerUser} from "../../common";

export const AddProduct = () => {
  const context = useContext(AppContext);
  const history = useHistory();
  const {id} = useParams();
  const defaultClasses = useAddProductStyles();
  const mobileClasses = useAddProductMobileStyles();
  const classes = context.isPhone ? mobileClasses : defaultClasses;
  const {showSuccess, showError} = useMessages();

  const [categories, setCategories] = useState(null);
  const [dormitories, setDormitories] = useState(null);
  const [initialValues, setInitialValues] = useState(DATA_DESCRIPTION);
  const [invalidImageStatuses, setInvalidImageStatuses] = useState([]);
  const [preview, setPreview] = useState(!!id);

  const {values, errors, handleChange, handleSubmit, setFieldValue, isSubmitting} = useFormik({
    initialValues: {...DATA_DESCRIPTION(!id)},
    onSubmit: () => submit(),
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: VALIDATION_SCHEMA,
  });

  useEffect(() => {
    getCategories().then((response) => setCategories(response))
  }, []);

  useEffect(() => {
    getDormitories().then((response) => setDormitories(response))
  }, []);

  useEffect(() => {
    !id && !!dormitories &&
    getUserDormitory(history)
      .then(dormId => setFieldValue("id_dormitory", dormId))
  }, [dormitories]);

  useEffect(() => {
    getUserGoodsCount(history)
      .then(response => {
        if (response >= goodsPerUser) {
          showError(MESSAGES.goods_count_error);
          history.push('/myadv');
        }
      })
      .catch(error => showError(error));
  }, []);

  useEffect(() => !!id && getGoodByID(history, id)
      .then((data) => {
        Object.keys(values)
          .forEach(key =>
            key === 'images'
              ? convertFiles(data[key], key, setFieldValue)
              : setFieldValue(key, data[Object.keys(data)
                .find(dataKey => dataKey === key)] || values[key])
          );
      })
      .then(() => setPreview(false))
    , []);

  useEffect(() => {
    id &&
    values.good_name !== "" &&
    initialValues.images[0]?.data_url === "" &&
    values.images[0]?.data_url !== "" &&
    setInitialValues(values);
  }, [values.images]);

  const postSubmitHandler = (response) => {
    if (Array.isArray(response)) {
      setInvalidImageStatuses(response);
      showError(MESSAGES.validation_error);
    } else if (Number.isInteger(response)) {
      showSuccess(MESSAGES.success);
      history.push(`/detail/${response}`);
    } else {
      return Promise.reject();
    }
  };

  const submit = () => {
    const requestBody = new FormData();

    Object.keys(values).forEach(key => key === 'images'
      ? !id && values.images.forEach(e => requestBody.append("image", e.file))
      : requestBody.append(key, values[key]));
    if (!!id) {
      requestBody.append("good_id", id);
      getRemovedUrls(initialValues.images, values.images).forEach(url => requestBody.append("deleted_photo_link", url));
      getAddedFiles(values.images).forEach(image => requestBody.append("image", image));
    }
    if (!isEquals(initialValues, values)) {
      return !id
        ? createGood(requestBody, history)
          .then(postSubmitHandler)
          .catch(error => showError(error))
        : updateGood(requestBody, history)
          .then(postSubmitHandler)
          .catch(error => showError(error));
    }
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        {id ? "Редактирование" : "Создание"} объявления
      </Typography>
      {!preview && <>
        <Divider/>
        <Typography variant="h6" className={classes.subtitle}>Фото</Typography>
        {((id && values.images[0]?.data_url !== "") || (!id)) &&
        <ImageDragBox
          setImages={(imageList) => setFieldValue('images', imageList)}
          images={values.images}
          disabled={isSubmitting}
          invalidImageStatuses={invalidImageStatuses}
          setInvalidImageStatuses={setInvalidImageStatuses}
        />}
        <Divider/>
        <form onSubmit={handleSubmit}>
          <div className={classes.inputs}>
            <Typography variant="h6" className={classes.subtitle}>Сведения о товаре</Typography>
            <TextField
              className={classes.textField}
              name="good_name"
              value={values.good_name}
              error={!!errors.good_name}
              helperText={errors.good_name}
              label="Название *"
              onChange={handleChange}
              variant="standard"
              disabled={isSubmitting}
            />
            <TextField
              className={classes.textField}
              name="price"
              label="Стоимость *"
              placeholder="₽"
              value={values.price}
              error={!!errors.price}
              helperText={errors.price}
              onChange={handleChange}
              id="formatted-numberformat-input"
              disabled={isSubmitting}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
            <TextField
              className={classes.description}
              name="description"
              value={values.description}
              error={!!errors.description}
              helperText={errors.description}
              variant="outlined"
              label="Описание"
              multiline
              maxRows={6}
              onChange={handleChange}
              disabled={isSubmitting}
            />
            <SelectList
              label="Общежитие *"
              rootClass={classes.textField}
              items={dormitories}
              name='id_dormitory'
              value={values.id_dormitory}
              error={!!errors.id_dormitory}
              helperText={errors.id_dormitory}
              disabled={isSubmitting}
              handleChange={handleChange}
            />
          </div>
          <Divider/>
          {!!categories &&
          <MultiItemsSelector
            name="id_subcategory"
            disabled={isSubmitting}
            error={errors.id_subcategory}
            setFieldValue={setFieldValue}
            value={values.id_subcategory}
            items={categories}
          />}
          <Divider/>
          <div className={classes.actions}>
            <StyledButton
              variant="outlined"
              disabled={isSubmitting}
              text="Отмена"
              handleClick={() => history.push('/myadv')}
            />
            <StyledButton
              type="submit"
              disabled={isSubmitting || isEquals(initialValues, values)}
              text={!id ? "Опубликовать" : "Изменить"}
            />
          </div>
          <Typography variant="caption" align="right" className={classes.eula}>
            {`Нажимая ${!id ? '"Опубликовать"' : '"Изменить"'}, Вы принимаете `}
            <Link href="https://drive.google.com/file/d/1oX-h_t2dOolDhWz6ig9pUJhReZvq4_K4/view" target="_blank">
              условия использования
            </Link>.
          </Typography>
        </form>
      </>}
      <Preview open={preview}/>
    </div>
  );
};

const NumberFormatCustom = (props) => {
  const {inputRef, onChange, ...other} = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      allowNegative={false}
      isAllowed={({value}) => value <= MAX_PRICE}
      decimalScale={0}
      suffix=" ₽"
    />
  );
}

const VALIDATION_SCHEMA = yup.object().shape({
  id_subcategory: yup.number().moreThan(0, "Выберите категорию товара"),
  id_dormitory: yup.number().moreThan(0, "Выберите общежитие"),
  price: yup.number().required("Обязательное поле")
    .min(0, "Цена не может быть отрицательной")
    .max(MAX_PRICE, "Цена не может быть больше 1,000,000"),
  good_name: yup.string().required("Обязательное поле")
    .min(2, "Название должно быть длиннее 2 символов")
    .max(60, "Название не может быть длиннее 60 символов")
    .test("goodNameMat", validationMessage, name => !!name && !containsMat(name)),
  description: yup.string()
    .max(400, "Описание не может превышать 400 символов")
    .test("descriptionMat", validationMessage, description => !!description ? !containsMat(description) : true),
});