import React from "react";
import { Paper, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    width: 'max-content', 
    backgroundColor: theme.palette.grey[100],
    "& :first-child": {
      padding: 5,
    }
  },
}));

export const InfoText = ({text, style = {margin: '10px 0'}, ...props}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root} elevation={0} {...props} style={style}>
      <Typography variant="subtitle1">
        {text}
      </Typography>
    </Paper>
  );
}