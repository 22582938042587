import React, {useState, useEffect} from "react";
import { Slider, TextField, InputAdornment } from "@material-ui/core";
import { usePriceSliderStyles } from "./priceSliderStyles";

let activeThumb = 0; //активный ползунок: 0 - левый, 1 - правый
const noSpace = '';

export const PriceSlider = ({min, max, name, initialValues}) => {
    const classes = usePriceSliderStyles();
    const [value, setValue] = useState(initialValues);
    
    useEffect(() => setValue(initialValues), [initialValues[0], initialValues[1]]);

    useEffect(() => min == max && setValue([min, max]), [min, max]);

    const handleChangeSlider = (event, newValue) => {
        const val = event.target.value;
        
        if(newValue[0] !== value[0] || newValue[1] !== value[1]) {
            if(newValue[0] !== value[0]) activeThumb = 0;
            else activeThumb = 1;
        }

        if (!Array.isArray(newValue)) {
            return;
        }
        
        if(+val < min || +val > max) return;

        if (+activeThumb === 0) {
            if(val === noSpace) {
                setValue([noSpace, value[1]]);
                return;
            }
            setValue([Math.min(newValue[0], value[1]), value[1]]);       
        } else {
            if(val === noSpace) {
                setValue([value[0], noSpace]);
                return;
            }
            setValue([value[0], Math.max(newValue[1], value[0])]);
        }
    };

    const handleBlur = () => {
        if(value[0] === noSpace || value[0] < min) setValue([min, value[1]]);
        else if(value[1] === noSpace || value[1] > max) setValue([value[0], max]);
        else if(value[0] > max) setValue([value[1], value[1]]);
        else if(value[1] < min) setValue([value[0], value[0]]);
        else if(value[0] > value[1]) setValue([value[1], value[0]]);
    };

    const handleChangeField = (event, newValue) => {
        const val = event.target.value;

        if(newValue[0] != value[0] || newValue[1] != value[1]) {
            if(newValue[0] !== value[0]) activeThumb = 0;
            else activeThumb = 1;
        }
        if (!Array.isArray(newValue)) {
            return;
        }

        if (+activeThumb === 0) {
            if(val === noSpace) {
                setValue([noSpace, value[1]]);
                return;
            }
            setValue([newValue[0], value[1]]);       
        } else {
            if(val === noSpace) {
                setValue([value[0], noSpace]);
                return;
            }
            setValue([value[0], newValue[1]]);
        }
    };

    return(
        <div>        
            <Slider
                className={classes.slider}
                min={min}
                max={max}   
                defaultValue={initialValues}
                value={value}
                onChange={handleChangeSlider}
            />
            <div className={classes.textInputContainer}>
                <TextField
                    name={name}
                    variant="outlined"
                    size="small"
                    value={value[0]}
                    onChange={(e) => handleChangeField(e, [+e.target.value, value[1]])}
                    onClick={(e) => activeThumb = 0}
                    onBlur={handleBlur}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">от</InputAdornment>,
                        type: 'number',
                        step: 1,
                        min: min,
                        max: max,
                    }}
                />
                <TextField
                    name={name}
                    variant="outlined"
                    size="small"
                    value={value[1]}
                    onChange={(e) => handleChangeField(e, [value[0], +e.target.value])}
                    onClick={(e) => activeThumb = 1}
                    onBlur={handleBlur}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">до</InputAdornment>,
                        type: 'number',
                        step: 1,
                        min: min,
                        max: max,
                    }}
                />
            </div>
        </div>
    );
}