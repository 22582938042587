import React from "react";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[200],
  },
  progress: {
    color: theme.palette.misis,
  },
}));

export const Preview = ({open}) => {
  const classes = useStyles();

  return(
  <Backdrop open={open} className={classes.root}>
    <CircularProgress size={60} className={classes.progress}/>
  </Backdrop>);
}