import { alpha, makeStyles } from "@material-ui/core/styles";

const searchStyles = (theme) => ({
  search: {
    position: "relative",
    display: "flex",
    borderRadius: theme.shape.borderRadius,
    marginLeft: 0,
    border: `2px solid ${theme.palette.misis}`,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      flexGrow: 3,
    },
  },
  searchIconButton: {
    height: "100%",
    display: "flex",
    margin: "auto",
    left: "5px",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      "& svg": {
        color: theme.palette.misisLight,
      },
    },
    "& svg": {
      transition: "color 500ms",
    },
  },
  searchIcon: {
    color: theme.palette.misis,
  },
  inputRoot: {
    margin: theme.spacing(0, 1),
    display: "inline-block",
    width: "95%",
    cursor: "default",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    display: "flex",
    transition: theme.transitions.create("width"),
    width: "100%",
  },
});

const searchMobileStyles = (theme) => ({
  ...searchStyles(theme),
  searchIconButton: {
    height: "100%",
    display: "flex",
    margin: "auto",
    left: "5px",
    alignItems: "center",
    justifyContent: "center",
  },
  search: {
    position: "relative",
    display: "flex",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "70%",
    border: `2px solid ${theme.palette.misis}`,
  },
  searchIcon: {
    color: "white",
  },
  inputRoot: {
    margin: theme.spacing(0, 1),
    display: "inline-block",
    color: "inherit",
    width: "95%",
    cursor: "default",
  },
});
export const useSearchStyles = makeStyles(searchStyles);
export const useSearchMobileStyles = makeStyles(searchMobileStyles);
