import React, {useContext} from "react";
import {Divider, Typography} from "@material-ui/core";
import {AppContext} from "../common";

export const PageTitle = ({title}) => {
  const {isPhone} = useContext(AppContext);

  return (
    <>
      <Typography variant={isPhone ? "h5" : "h4"} style={{margin: '15px 0'}}>{title}</Typography>
      <Divider/>
    </>
  );
}