import React from "react";
import { Tooltip, IconButton } from "@material-ui/core";

export const IconButtonTooltip = ({icon, title, className, style, onClick}) => {
    return (
        <Tooltip title={title} placement="right-start">
            <IconButton
                className={className}
                variant="contained"
                size="small"
                style={style}
                onClick={onClick}
            >
                {icon}
            </IconButton>
        </Tooltip>
    );
};