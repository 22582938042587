import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import Pagination from '@material-ui/lab/Pagination';
import { usePagintaionStyles } from './pagintationStyles';
import { cardsPerPage, isFavPage } from '../../common';
import { getGoodsCount } from '../../api/productAPI';

const calcCountPage = (goodsCount) => {
  return +goodsCount <= cardsPerPage ? 1 : Math.ceil(goodsCount / cardsPerPage);
};

export const PaginationBar = ({id, className}) => {
  const classes = usePagintaionStyles();
  const {page} = useParams();
  const [value, setValue] = useState(+page);
  const [pagesCount, setPagesCount] = useState(1);
  const history = useHistory();

  useEffect(() => setValue(+page), [page]);
  
  useEffect(() => {
    getGoodsCount(history, isFavPage(history))
      .then(response => setPagesCount(calcCountPage(response)));
  }, [history.location.search]);

  const handleChangePage = (event, value) => {
    setValue(value);
    history.push(`/${isFavPage(history) ? 'fav' : 'page'}/${value}` + history.location.search);
  };

  return (pagesCount > 1 &&
    <div id={id} className={className}>
      <Pagination 
        defaultPage={1}
        siblingCount={3}
        className={classes.root}
        count={pagesCount}
        page={value}
        onChange={handleChangePage}
      />
    </div>
  );
}