import React from "react";
import {Skeleton} from "@material-ui/lab";
import {Avatar} from "@material-ui/core";

export const UserAvatar = ({userNameSurname, source, className, preview = false, size = 40}) =>
  preview
    ?
    <Skeleton variant='circle' width={size} height={size}/>
    :
    <Avatar
      className={className}
      {...avatarByName(userNameSurname)}
      src={source}
    />

const avatarByName = name => ({
  sx: {
    bgcolor: stringToColor(name),
  },
  children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
});

function stringToColor(string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }

  return color;
};