import React, {useState, useEffect, useContext} from "react";
import {useHistory, useParams} from "react-router-dom";
import {Typography, Divider, Paper, Button} from "@material-ui/core";
import {useDetailStyles, useDetailMobileStyles} from "./detailStyles";
import {
  AppContext,
  createCategoryViewName,
  getUser,
  isAdult,
  setIsAdult,
  tobaccoProductSubcategory
} from "../../common";
import {getGoodByID, getUserInfo, changeFavorite, deleteGood} from "../../api/productAPI";
import {ImageView} from "./image-view/ImageView";
import {OwnerCard} from "./owner-card/OwnerCard";
import {useMessages} from "../../components/messages";
import is_adult from "./is_adult.jpg";
import {StyledButton} from "../../components/StyledButton";

export const Detail = ({isPreview}) => {
  const {isPhone} = useContext(AppContext);
  const history = useHistory();
  const user = getUser();
  const {id} = useParams();
  const [data, setData] = useState({});
  const [ownerInfo, setOwnerInfo] = useState();
  const [preview, setPreview] = useState(true);
  const [adult, setAdult] = useState(isAdult());
  const {showSuccess, showError} = useMessages();

  const defaultClasses = useDetailStyles();
  const mobileClasses = useDetailMobileStyles();
  const classes = isPhone ? mobileClasses : defaultClasses;
  const isOwner = data?.id_user === user?.id;

  useEffect(() => {
    setPreview(true);
    getGoodByID(history, id, isPreview)
      .then((response) => {
        setData(response);
        getUserInfo(response.id_user)
          .then((response) => {
            setOwnerInfo(response);
            setPreview(false);
          });
      })
      .catch(error => showError(error));
  }, []);

  const handleClickDelete = () =>
    deleteGood(data.id, history)
      .then(() => {
        showSuccess("Объявление удалено");
        history.replace('/myadv');
      })
      .catch((error) => showError(error));

  return (!isPreview && !!id && !isOwner && !adult && data?.subcategory === tobaccoProductSubcategory
      ? <CheckIsAdult history={history} callback={() => setAdult(true)}/>
      : <>
        <div className={classes.root}>
          <div className={classes.mainContainer}>
            <div style={{width: "100%"}}>
              <Typography variant={isPhone ? "h5" : "h4"} className={classes.title}>
                {data.good_name}
              </Typography>
              <div className={classes.twoColumnsContainer}>
                <div className={classes.leftSide}>
                  <ImageView images={data.images || []}/>
                  <div className={classes.goodInfo}>
                    <Divider style={{margin: '8px 0'}}/>
                    <SubInfoRow classes={classes} left="Дата публикации" right={data.date}/>
                    <SubInfoRow classes={classes} left="Категория" right={createCategoryViewName(data.category, data.subcategory)}/>
                    <SubInfoRow classes={classes} left="Адрес" right={!!data.dormitory
                      ? `${data.dormitory} ${data?.dormitory_address ? '(' + data.dormitory_address + ')' : ""}`
                      : ""}
                    />
                    <Divider style={{margin: '7px 0'}}/>
                    {!!data.description &&
                      <>
                        <Typography variant={isPhone ? "subtitle1" : "h6"}>Описание:</Typography>
                        <Typography variant="subtitle2">{data.description}</Typography>
                        <Divider style={{margin: '8px 0'}}/>
                      </>
                    }
                  </div>
                </div>
                <OwnerCard
                  id={id}
                  role={user?.role}
                  isOwner={isOwner}
                  ownerInfo={ownerInfo}
                  price={data.price}
                  defaultIsFavorite={data.is_favorite}
                  handleClickFavorite={() => changeFavorite(id)}
                  handleClickDelete={handleClickDelete}
                  preview={preview}
                  disableAll={isPreview}
                />
              </div>
            </div>
          </div>
        </div>
      </>
  );
};

const SubInfoRow = ({left, right, classes}) =>
  <div className={classes.subInfo}>
    <Typography variant="subtitle2" color="textSecondary">{left}:&nbsp;</Typography>
    <Typography variant="subtitle2">{right}</Typography>
  </div>

const CheckIsAdult = ({history, callback}) => {
  const onClickYes = () => {
    setIsAdult();
    callback();
  }

  return (
    <div style={{textAlign: "center"}}>
      <img src={is_adult} width={350} style={{margin: 'auto', display: 'block'}}/>
      <div style={{marginTop: 10}}>
        <StyledButton
          size="large"
          variant="contained"
          text="Мне есть 18 лет"
          handleClick={onClickYes}
        />
        <StyledButton
          style={{marginLeft: 10}}
          size="large"
          variant="outlined"
          text="нет"
          handleClick={() => history.replace("/page/1")}
        />
      </div>
    </div>
  );
}