import { makeStyles } from "@material-ui/styles";
import {commonStyles} from "../../commonStyles";

const addProductStyles = (theme) => ({
  ...commonStyles(theme),
  root: {
    maxWidth: 900,
    margin: 'auto',
    paddingBottom: theme.spacing(5),
  },
  textField: {
    minWidth: 'max-content',
    width: '35%', 
    display: 'block',
    '& div': {
      width: '100%',
    }
  },
  inputs: {
    display: 'flex',
    flexDirection: 'column',
    gap: 25,
    marginBottom: theme.spacing(1),
  },
  description: {
    width: 664,
    '& textarea': {
      width: 636,
      fontSize: '0.875rem',
    }
  },
  actions: {
    display: "flex",
    justifyContent: "right",
    padding: theme.spacing(2),
    gap: 20,
  },
  eula: {
    display: 'block',
    color: theme.palette.text.secondary,
    '& a': {color: theme.palette.misis}
  },
});

const addProductMobileStyles = (theme) => ({
  ...commonStyles(theme),
  ...addProductStyles(theme),
  root: {
    margin: '1rem',
  },
  description: {
    width: '100%',
  },
  textField: {
    minWidth: 'max-content',
    width: '100%', 
    display: 'block',
    '& div': {
      width: '100%',
    }
  },

});

export const useAddProductStyles = makeStyles(addProductStyles);
export const useAddProductMobileStyles = makeStyles(addProductMobileStyles);