import React from "react";
import {useLocation} from "react-router-dom";
import {Button, Box, Paper, Typography} from "@material-ui/core";
import {Icon24LogoVk} from "@vkontakte/icons";
import {useSignInStyles} from "./signInStyles";
import logo from "./logo.png";
import {Copyright} from "../../components/Copyright";
import {FRONTEND_URL} from "../../common";

const VK_API_PATH = (redirect_url) => `https://oauth.vk.com/authorize?client_id=7967076&display=popup&redirect_uri=${FRONTEND_URL + redirect_url}&scope=email&response_type=code&v=5.131`;
const MISIS_API_PATH = (redirect_url) => `https://auth.misis.ru/adfs/oauth2/authorize?client_id=bb4eea31-e8ab-4513-8e1b-a2020c52f3e4&redirect_uri=${FRONTEND_URL + redirect_url}&response_type=id_token+token&scope=openid`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const signInVK = (redirect_url) => {
  signIn(VK_API_PATH, redirect_url);
};

const signInMisis = (redirect_url) => {
  signIn(MISIS_API_PATH, redirect_url);
};

const signIn = (api_path, redirect_url) => {
  redirect_url = !!redirect_url ? redirect_url : "/page/1";
  window.open(
    api_path(redirect_url),
    "_self",
  );
}

export const SignIn = () => {
  const classes = useSignInStyles();
  let query = useQuery();
  const target_url = query.get("target_url");

  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <img src={logo} width={50} alt={"misis"}/>
          <Typography
            component="h1"
            variant="h5"
            style={{marginBottom: "30px"}}
          >
            Авторизация
          </Typography>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{backgroundColor: '#0077ff'}}
            startIcon={<Icon24LogoVk/>}
            onClick={() => signInVK(target_url)}
          >
            Войти
          </Button>
          <Box mt={8}>
            <Copyright/>
          </Box>
        </Paper>
      </div>
    </>
  );
};
