import React, {useContext, useEffect, useState} from "react";
import {ModerationRequestCard} from "../../components/moderation-request-card/ModerationRequestCard";
import {useModerationRequestListPageMobileStyles, useModerationRequestListPageStyles} from "./moderationRequestListPageStyles";
import {PageTitle} from "../../components/PageTitle";
import {getModerationRequests} from "../../api/moderationAPI";
import {AppContext, getCurrentUrlParams, handleChooseSortItem, ids} from "../../common";
import {ButtonList} from "../../components/button-list/ButtonList";
import {sortItems} from "../product-feed/productFeedUtils";
import {useHistory} from "react-router-dom";

export const ModerationRequestListPage = () => {
  const params = getCurrentUrlParams();
  const history = useHistory();
  const {isPhone} = useContext(AppContext);
  const defaultClasses = useModerationRequestListPageStyles();
  const mobileCLasses = useModerationRequestListPageMobileStyles();
  const classes = isPhone ? mobileCLasses : defaultClasses;

  const [requests, setRequests] = useState(null);

  useEffect(() => {
      getModerationRequests(params.sort_by)
        .then((response) => setRequests(response))
  }, [history.location]);

  return (requests
      ?
      <div className={classes.root}>
        <PageTitle title="Заявки на модерацию"/>
        <ButtonList
          id={ids.sortGoodsId}
          initialValue={params.sort_by}
          values={[sortItems[0], sortItems[1]]}
          handleChooseItem={(value) => handleChooseSortItem(history, value)}
        />
        <div className={classes.requestList}>
          {requests.map(item => <ModerationRequestCard data={item}/>)}
        </div>
      </div>
      : <></>
  );
}