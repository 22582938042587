import React from "react";
import {Link, makeStyles} from "@material-ui/core";
import { Icon28LogoVkColor } from "@vkontakte/icons";

export const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {INFORMATION.map((item, index) =>
        <Link key={`footerInfo${index}`} href={item.link} target="_blank" className={classes.link}>
          {item.name}
        </Link>
      )}
      <Link href={"https://vk.com/public209778412"} target="_blank">
        <p style={{display: 'inline-block', 'vertical-align': 'super', 'margin-top': '-10px'}}>Мы в VK </p>
        <Icon28LogoVkColor style={{display: 'inline-block'}}/>
      </Link>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    flexWrap: "wrap",
    height: 160,
    width: '100%',
    backgroundColor: theme.palette.grey[200],
  },
  link: {
    '&:hover': {
      color: theme.palette.primary,
    },
  },
}));

const INFORMATION = [
  {
    name: "Условия использования",
    link: "https://drive.google.com/file/d/1oX-h_t2dOolDhWz6ig9pUJhReZvq4_K4/view",
  },
  {
    name: "Помощь",
    link: "https://vk.com/topic-209778412_49104244",
  },
  {
    name: "Обратная связь",
    link: "https://vk.com/topic-209778412_48170215",
  },
];