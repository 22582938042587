import React, { useState, useEffect, useContext } from "react";
import { Typography, Paper } from "@material-ui/core";
import { PermIdentity, ExitToApp, Edit } from "@material-ui/icons";
import { StyledButton } from "../../components/StyledButton";
import { useProfileStyles, useProfileMobileStyles } from "./profileStyles";
import {AppContext, getCurrentUrlParams, toString} from "../../common";
import { getUserInfoAdv } from "../../api/accountAPI";
import { getDormitories } from "../../api/productAPI";
import { UserInfoForm } from "./user-info-form/UserInfoForm";
import { UserInfoView } from "./user-info-view/UserInfoView";
import { UserAvatar } from "../../components/UserAvatar";
import {TypographyPreviewfy} from "../../components/TypographyPreviewfy";
import {useMessages} from "../../components/messages";
import {useHistory} from "react-router-dom";

export function Profile() {
  const {isPhone} = useContext(AppContext);
  const history = useHistory();
  const defaultClasses = useProfileStyles();
  const mobileClasses = useProfileMobileStyles();
  const classes = isPhone ? mobileClasses : defaultClasses;
  const {showInfo, showError} = useMessages();

  const [editMode, setEditMode] = useState(false);
  const [dormitories, setDormitories] = useState();
  const [userInfo, setUserInfo] = useState();
  const [preview, setPreview] = useState(true);
  const [updated, notify] = useState(false); 

  const callback = () => notify(!updated);

  const handleClickExit = () => {
    localStorage.removeItem("token");
    history.push("/signin");
  };

  useEffect(() => {
    getUserInfoAdv(history)
      .then(response => {
        Object.keys(response).forEach(key => response[key] = toString(response[key]));
        setUserInfo(response)
      })
      .then(() => setPreview(false))
      .catch(error => showError(error));
  }, [updated]);

  useEffect(() => {
    getDormitories()
      .then(response => setDormitories(response))
      .catch(error => showError(error));
  }, []);

  useEffect(() => !preview && getCurrentUrlParams()['new_user'] === '1' &&
      showInfo("Привет! Заполни информацию о себе, чтобы покупатели смогли связаться с тобой"), [preview]);

  return (
    <div className={classes.root}>
      <Typography variant={isPhone ? "h5" : "h4"} className={classes.title}> Профиль </Typography>
      <Paper className={classes.paper} elevation={1}>
        <div className={classes.userView}>
          <UserAvatar 
            className={classes.avatar} 
            userNameSurname={userInfo?.user_name_surname}
            source={userInfo?.vk_picture_link}
            preview={preview}
            size={84}
          />
          <TypographyPreviewfy preview={preview} variant="h5" className={classes.name} text={userInfo?.user_name_surname?.split(" ")[0]}/>
          <TypographyPreviewfy preview={preview} variant="h5" className={classes.name} text={userInfo?.user_name_surname?.split(" ")[1]}/>
          {!editMode &&
          <>
          <StyledButton
            size='small'
            style={{ marginTop: "1rem" }}
            text='Редактировать'
            icon={<Edit/>}
            handleClick={() => setEditMode(!editMode)}
            disabled={preview}
          />
          <StyledButton
            variant="outlined"
            size='small'
            style={{ marginTop: "1rem" }}
            text='Выйти'
            icon={<ExitToApp/>}
            handleClick={handleClickExit}
          />
          </>
          }
        </div>
        {editMode ?
        <div className={classes.userInfo}>
          <UserInfoForm
            classes={classes}
            userInfo={userInfo}
            dormitories={dormitories}
            handleClose={() => setEditMode(false)}
            callback={callback}
          />
        </div>
        :
        <div className={classes.personalInfo}>
          <div className={classes.paperHeader}>
            <PermIdentity />
            <Typography variant="h6" className={classes.paperHeaderText}>Учётные данные</Typography>
          </div>
          <div className={classes.userInfo}>
            <UserInfoView userInfo={userInfo} preview={preview}/>
          </div>
        </div>
        }
      </Paper>
    </div>
  );
}
