import React, {useContext, useRef, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {Detail} from "../detail/Detail";
import {useModerationRequestMobileStyles, useModerationRequestStyles} from "./moderationRequestStyles";
import {Paper, TextField, Typography} from "@material-ui/core";
import {PageTitle} from "../../components/PageTitle";
import {StyledButton} from "../../components/StyledButton";
import {AppContext, STATUS} from "../../common";
import {sendModerationVerdict} from "../../api/moderationAPI";
import {ButtonList} from "../../components/button-list/ButtonList";

const feedbackMinSize = 10;
const feedbackTemplateMessages = [
  {value: "Не указано", name: 0},
  {value: "Одно или несколько фото нарушает правила сайта", name: 1},
  {value: "Пошёл нахуй", name: 2}
];

export const ModerationRequest = () => {
  const {isPhone} = useContext(AppContext);
  const history = useHistory();
  const {id} = useParams();
  const [error, setError] = useState({});
  const [feedback, setFeedback] = useState("");

  const defaultClasses = useModerationRequestStyles();
  const mobileClasses = useModerationRequestMobileStyles();
  const classes = isPhone ? mobileClasses : defaultClasses;

  const afterSubmit = () => history.replace("/moderation");

  const handleAccept = () => {
    setError({});
    sendModerationVerdict(id, STATUS.ACTIVE.value)
      .then(afterSubmit);
  };

  const handleRevoke = () => {
    if (!feedback) {
      setError({feedback: "Необходимо заполнить причину отклонения"})
    } else if (feedback.length < feedbackMinSize) {
      setError({feedback: `Не менее ${feedbackMinSize} символов`});
    } else {
      sendModerationVerdict(id, STATUS.AMENDING.value, feedback)
        .then(afterSubmit);
    }
  };

  const handleChange = ({target}) => {
    !!target.value && target.value?.length >= feedbackMinSize && setError({});
    setFeedback(target.value);
  };

  const handleChooseTemplateMessage = (ind) => {
      if (ind === 0) {
        setFeedback("");
      } else {
        setFeedback(feedbackTemplateMessages[ind].value);
      }
  }

  return (
    <div className={classes.root}>
      <PageTitle title="Модерация товара"/>
      <Paper elevation={2} className={classes.goodPreview}>
        <Typography variant={isPhone ? "subtitle1" : "h6"} color="textSecondary" className={classes.sectionTitle}>
          Предварительный просмотр
        </Typography>
        <Detail isPreview={true}/>
      </Paper>
      <Paper elevation={2} className={classes.feedback}>
        <Typography variant={isPhone ? "subtitle1" : "h6"} color="textSecondary" className={classes.sectionTitle}>
          Решение
        </Typography>
        <div className={classes.feedbackContent}>
          <TextField
            value={feedback}
            name="feedback"
            variant="outlined"
            multiline
            label="Замечания"
            maxRows={6}
            error={!!error.feedback}
            helperText={error.feedback}
            onChange={handleChange}
          />
          <ButtonList
            className={classes.defaultFeedback}
            values={feedbackTemplateMessages}
            handleChooseItem={handleChooseTemplateMessage}
          />
          <div className={classes.actions}>
            <StyledButton
              text="Принять"
              handleClick={handleAccept}
            />
            <StyledButton
              variant="outlined"
              text="Отклонить"
              handleClick={handleRevoke}
            />
          </div>
        </div>
      </Paper>
    </div>
  );
}