import {makeStyles} from "@material-ui/core/styles";

const productPageStyles = theme => ({
  root: {
    display: 'flex', 
    flexWrap: 'wrap', 
    gap: '15px', 
    justifyContent: 'flex-start', 
  },
});

const productPageMobileStyles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: 'auto',
    justifyContent: 'center',
  },
});

export const useProductPageStyles = makeStyles(productPageStyles);
export const useProductPageMobileStyles = makeStyles(productPageMobileStyles);