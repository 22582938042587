import {makeStyles} from "@material-ui/core/styles";

const paginationStyles = theme => ({
    root: {
        padding: '15px 0',
        display: 'flex',
        justifyContent: 'center',
    },
});

export const usePagintaionStyles = makeStyles(paginationStyles);