import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { NavigationBarView } from "./NavigationBarView";
import { getUserInfoBrief } from "../../api/accountAPI";
import { projectName, ids, AppContext } from "../../common";

export const NavigationBar = () => {
  const history = useHistory();
  const context = useContext(AppContext);

  const [userInfo, setUserInfo] = useState({});

  const handlers = [
    () => history.push("/myadv"),
    () => history.push("/fav/1"),
    () => history.push("/profile"),
    () => history.push("/page/1"),
    () => history.push("/moderation")
  ];

  const updateUserInfo = () => getUserInfoBrief().then(response => setUserInfo(response));

  useEffect(() => {
    updateUserInfo();
  }, []);

  context.updateUserInfo = updateUserInfo;

  return (
    <NavigationBarView
      title={projectName}
      id={ids.navBarId}
      handlers={handlers}
      userInfo={userInfo}
    />
  );
};
