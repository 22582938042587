import {makeStyles} from "@material-ui/core";

const ModerationRequestListPageStyles = theme => ({
  root: {
    marginLeft: theme.rootLeftMargin,
    width: "100%",
  },
  requestList: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    display: 'flex',
  }
});

const ModerationRequestListPageMobileStyles = theme => ({
  root: {
    width: "100%",
  },
  requestList: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    display: 'flex',
    justifyContent: 'center',
  }
});

export const useModerationRequestListPageStyles = makeStyles(ModerationRequestListPageStyles, {name: "ModerationRequestListPageStyles"});
export const useModerationRequestListPageMobileStyles = makeStyles(ModerationRequestListPageMobileStyles, {name: "ModerationRequestListPageMobileStyles"});