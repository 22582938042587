import {makeStyles} from "@material-ui/core/styles";

const userInfoFormStyles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  textField: {
    margin: "1rem 0 0 0.6rem", 
    display: 'inline-block', 
    width: '100%',
  },
  actions: {
    display: "flex",
    alignItems: 'end',
    justifyContent: 'right',
    marginTop: 80,
    width: '45%',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  itemContainer: {
    width: '45%',
    '& div:first-child': {
      display: 'flex', 
    },
  },
});

const userInfoFormMobileStyles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    height: '95%',
    flexDirection: 'column',
  },
  textField: {
    margin: "1rem 0 0 0.6rem", 
    display: 'inline-block', 
    width: '100%',
  },
  actions: {
    display: "flex",
    alignItems: 'end',
    justifyContent: 'right',
    marginTop: 30,
    width: '100%',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  itemContainer: {
    width: '100%',
    '& div': {
      display: 'flex',
    },
  },
});

export const useUserInfoFormStyles = makeStyles(userInfoFormStyles);
export const useUserInfoFormMobileStyles = makeStyles(userInfoFormMobileStyles);