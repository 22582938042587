import React, {useState, useContext} from "react";
import {IconButton, Paper, Dialog, Typography} from "@material-ui/core";
import {StyledButton} from "../../../components/StyledButton";
import {Cached, Delete, CheckCircle, Cancel, ArrowBackIos as LeftIcon, ArrowForwardIos as RightIcon} from "@material-ui/icons";
import ImageUploading from "react-images-uploading";
import {ErrorValidation} from "../../../components/ErrorValidation";
import {useImageDragBoxStyles, useImageDragBoxMobileStyles} from "./imageDragBoxStyles";
import {AppContext} from "../../../common";

const maxNumber = 6;
const maxFileSize = 10_000_000;
const uploadId = "uploadButton";
const acceptTypes = ['jpg', 'png', 'jpeg', 'webp', 'heic', 'jfif'];

export const ImageDragBox = ({images, invalidImageStatuses, setImages, setInvalidImageStatuses, disabled}) => {
  const defaultClasses = useImageDragBoxStyles();
  const mobileClasses = useImageDragBoxMobileStyles();
  const {isPhone} = useContext(AppContext);
  const classes = isPhone ? mobileClasses : defaultClasses;

  const [openedImageInd, setOpenedImageInd] = useState(null);
  const [imageIsOpen, setImageIsOpen] = useState(false);
  const [dragging, setDragging] = useState(false);

  const onChange = (imageList, addUpdateIndex) => setImages(imageList);

  const onError = (errorsType, files) => {
    errorsType.maxNumber && onChange(files.filter((image, ind) => ind <= (maxNumber - images.length)));

    if(errorsType.maxFileSize) {
      const validFiles = [];
      files.forEach(image => image.file.size <= maxFileSize && validFiles.push(image));
      onChange(files.filter((image, ind) => ind <= (maxNumber - images.length)));
    }
    if(errorsType.acceptType) {
      const validFiles = [];
      files.forEach(image => acceptTypes.includes(image.file.type.split('/')[1]) && validFiles.push(image));
      onChange(files.filter((image, ind) => ind <= (maxNumber - images.length)));
    }
  };

  const removeImage = (index) => {
    const newStatuses = invalidImageStatuses.slice(0, index);

    for(let i = index+1; i < invalidImageStatuses.length; i++) {
      newStatuses.push(invalidImageStatuses[i]);
    }
    setInvalidImageStatuses(newStatuses);
  };

  const handleClickOnPhoto = ind => {
    setImageIsOpen(true);
    setOpenedImageInd(ind);
  };

  const handleReplaceImage = (index, direction) => {
    const copyOfImages = images.slice();
    const copyOfInvalidStatuses = invalidImageStatuses.slice();
    const memorizedValue = copyOfImages[index];
    const memorizedValue2 = copyOfInvalidStatuses[index];

    if(direction) {
      if(index + 1 < images.length) {
        copyOfImages[index] = copyOfImages[index + 1];
        copyOfImages[index + 1] = memorizedValue;
        copyOfInvalidStatuses[index] = copyOfInvalidStatuses[index + 1];
        copyOfInvalidStatuses[index + 1] = memorizedValue2;

        setImages(copyOfImages);
        setInvalidImageStatuses(copyOfInvalidStatuses);
      }
    } else {
      if(index - 1 >= 0) {
        copyOfImages[index] = copyOfImages[index - 1];
        copyOfImages[index - 1] = memorizedValue;
        copyOfInvalidStatuses[index] = copyOfInvalidStatuses[index - 1];
        copyOfInvalidStatuses[index - 1] = memorizedValue2;

        setImages(copyOfImages);
        setInvalidImageStatuses(copyOfInvalidStatuses);
      }
    }
  };

  return (
    <>
      <Typography variant="body2">{`Загружено: ${images.length} / ${maxNumber}`}</Typography>
      <Typography variant="body2" color="textSecondary">{`*Максимальный размер фото: ${maxFileSize / 1_000_000} Мб`}</Typography>
      <div className={classes.root}>
        <Dialog className={classes.openedImage} open={imageIsOpen} onClose={() => setImageIsOpen(false)}>
          <Paper style={{padding: '5px 5px 3px 5px'}}>
            <img src={images[openedImageInd]?.data_url} alt={"Modal"} className={classes.dialogImage}/>
          </Paper>
        </Dialog>
        <ImageUploading
          multiple
          value={images}
          onChange={onChange}
          onError={onError}
          maxNumber={maxNumber}
          maxFileSize={maxFileSize}
          dataURLKey="data_url"
          acceptType={acceptTypes}
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            errors,
            dragProps,
          }) => (
            <div className={classes.mainContainer}>
              <div className={classes.imageHarness}>
                {imageList.map((image, index) => (
                  <div key={index}>
                    <div className={classes.imageContainer} onClick={() => handleClickOnPhoto(index)}>
                      <img className={classes.image} src={image.data_url} alt=""/>
                      {invalidImageStatuses[index] === true
                        ? <CheckCircle className={classes.statusSuccess} titleAccess="Валидация пройдена"/>
                        : invalidImageStatuses[index] === false
                          ? <Cancel className={classes.statusError} titleAccess="Валидация не пройдена"/>
                          : <></>}
                    </div>
                    <div className={classes.imageActions}>
                      <IconButton disabled={disabled || index - 1 < 0} onClick={() => handleReplaceImage(index, 0)}>
                        <LeftIcon/>
                      </IconButton>
                      <IconButton disabled={disabled} onClick={() => {removeImage(index); onImageUpdate(index)}}>
                        <Cached/>
                      </IconButton>
                      <IconButton disabled={disabled} onClick={() => {removeImage(index); onImageRemove(index)}}>
                        <Delete/>
                      </IconButton>
                      <IconButton disabled={disabled || index + 1 >= imageList.length} onClick={() => handleReplaceImage(index, 1)}>
                        <RightIcon/>
                      </IconButton>
                    </div>
                  </div>
                ))}
                {imageList.length < maxNumber &&
                <button
                  id={uploadId}
                  disabled={disabled}
                  onDragEnterCapture={() => setDragging(true)}
                  onDragLeaveCapture={() => setDragging(false)}
                  onDropCapture={() => setDragging(false)}
                  className={`${classes.uploadButton} ${dragging && classes.dragging}`}
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  Нажмите или перенесите сюда фото
                </button>}
                <Errors errors={errors}/>
              </div>
              &nbsp;
              {imageList.length !== 0 &&
              <StyledButton
                disabled={disabled}
                className={classes.deleteAllButton}
                variant="outlined"
                handleClick={() => {
                  setInvalidImageStatuses([]);
                  onImageRemoveAll();
                }}
                text="Удалить все"
              />}
            </div>
          )}
        </ImageUploading>
      </div>
    </>
  );
};

const Errors = ({errors}) => !!errors &&
  <div>
    {errors.maxNumber && <ErrorValidation error={`Нельзя загружать больше ${maxNumber} фото`}/>}
    {errors.acceptType && <ErrorValidation error="Один или несколько файлов имеют недопустимый формат"/>}
    {errors.maxFileSize && <ErrorValidation error={`Превышен максимально допустимый размер фото (${maxFileSize / 1_000_000} Мб)`}/>}
  </div>