import React from "react";
import ErrorIcon from "@material-ui/icons/Error";
import {makeStyles, Typography} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'end',
  },
}));

export const ErrorValidation = ({error, className}) => {
  const classes = useStyles();

  return (
    <div className={`${className} ${classes.root}`} style={!!error ? undefined : {opacity: 0}}>
      <ErrorIcon color="error"/>
      <Typography variant="caption" color="error">{error}</Typography>
    </div>
  );
}