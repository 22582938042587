import {createContext} from "react";
import jwtDecode from "jwt-decode";

const chooseBackendUrl = () => {
  switch (process.env.REACT_APP_STAGE) {
    case 'test': return 'https://test.misis-marketplace.ru/baraholka-backend/';
    case 'production': return 'https://misis-marketplace.ru/baraholka-backend/';
    default: return 'http://localhost:3001/baraholka-backend/';
  }
};

const chooseFrontendUrl = () => {
  switch (process.env.REACT_APP_STAGE) {
    case 'test': return 'https://test.misis-marketplace.ru';
    case 'production': return 'https://misis-marketplace.ru';
    default: return 'http://localhost:3000';
  }
};

export const BASENAME = chooseBackendUrl();
export const FRONTEND_URL = chooseFrontendUrl();
export const projectName = "MISIS Marketplace";
export const cardsPerPage = 24;
export const goodsPerUser = 25;
export const commonHeaders = {Accept: "application/json", "Content-Type": "application/json;charset=UTF-8"};
export const subListMaxHeight = 150;
export const tobaccoProductSubcategory = "Табачная продукция";
export const anotherSubcategory = "Другое";
export const names = {
  categoriesCheckboxName: 'categoryCb',
  priceValuesName: 'priceValues',
  dormitoryCheckboxName: 'dormitoryCb',
  sortDate: 'date',
  sortAscPrice: 'price_asc',
  sortDescPrice: 'price_desc',
  sortAscDate: 'date_asc',
  sortDescDate: 'date_desc',
  defaultSort: 'date_desc',
};
export const ids = {
  navBarId: 'navBar',
  priceId: 'priceContainer',
  submitId: 'submitContainer',
  listHeaderId: 'header',
  sortGoodsId: 'sortGoods',
  queryId: 'query',
};
export const AppContext = createContext({
  sideBarIsOpen: false,
  page: 1,
});

export const STATUS = {
  AWAITING: {title: "Ожидает модерации", value: "awaiting"},
  PENDING: {title: "На модерации", value: 'pending'},
  AMENDING: {title: "Отклонено", value: 'amending'},
  ACTIVE: {title: "Опубликовано", value: 'active'},
  ARCHIVE: {title: "Архив", value: 'archive'},
};

export const getToken = () => localStorage.getItem('token');

export const getUser = () => {
  const token = getToken();

  return token ? jwtDecode(token) : null;
};

export const getAuthHeader = (useType = true) => {
  const props = {credentials: "same-origin"};
  const jwt = getToken();
  if (jwt) {
    props.headers = {Authorization: jwt};
    props.headers = useType ? {...props.headers, "Content-Type": "application/json;charset=UTF-8"} : props.headers;
  }

  return props;
};

export const errorHandler = (response, history, redirect = true) => {
  const status = response.status;
  if (status === 401) {
    localStorage.removeItem('token');
    if (redirect && !!history) {
      history.push("/signin");
    } else {
      return reject(response);
    }
  } else if (status === 404) {
    history.replace("/notfound");
  } else if (status === 400 || (status >= 401 && status < 500)) {
    return reject(response);
  } else if (status >= 500) {
    return Promise.reject("Ошибка сервера");
  }

  return response;
};

const reject = (response) => new Promise((resolve, reject) => response.json().then(json => reject(json.detail)));

export const formatString = (string, nameLimit) => string?.length > nameLimit ? string.slice(0, nameLimit - 3) + '...' : string;

export const phoneFormat = p => p?.length === 10 && `+7(${p[0]}${p[1]}${p[2]})-${p[3]}${p[4]}${p[5]}-${p[6]}${p[7]}-${p[8]}${p[9]}`;

export const isTrueObject = (value) => !!value && (Number.isNaN(value) ? value?.length !== 0 : true);

export const getValue = (value, alter) => !!value ? value : alter;

export const toString = value => value === undefined || value === null ? "" : value;

export const getCurrentUrlParams = () => {
  const params = new URLSearchParams(decodeURI(window.location.search));

  let result = {};
  for (let [param, value] of params.entries()) {
    if(param.endsWith("[]")) {
      param = param.slice(0, -2);
      Array.isArray(result[param])
        ? result[param].push(value)
        : result[param] = [value];
    } else {
      result[param] = value;
    }
  }
  return result;
};

const fillParamsFromArray = (name, array, params) => {
  array.forEach(value => params.append(name, value));
  return params.toString();
}

export const fillUrlParamsForRequest = (params) => {
  const currentParams = getCurrentUrlParams();
  if (!!currentParams.query) {
    isTrueObject(currentParams.query) && params.set('query', currentParams.query);
  } else {
    isTrueObject(currentParams.categories) && fillParamsFromArray('id_subcategories', currentParams.categories, params);
    isTrueObject(currentParams.dormitories) && fillParamsFromArray('id_dormitories', currentParams.dormitories, params);
    isTrueObject(currentParams.sort_by) ? params.set('sort_by', currentParams.sort_by) : params.set('sort_by', names.defaultSort);
    !!currentParams.minPrice && params.set('min_price', currentParams.minPrice);
    !!currentParams.maxPrice && params.set('max_price', currentParams.maxPrice);
  }
};

export const updateURL = (history, values, pathname) => {
  const url = new URL(window.location.href);
  url.pathname = !!pathname ? pathname : url.pathname;
  url.search = "";
  const params = url.searchParams;

  Object.keys(values).forEach(param => {
    const val = values[param];
    if (!!val) {
      if (Array.isArray(val)) {
        val.length !== 0 && val.forEach(val => params.append(param, val))
      } else {
        params.append(param, val);
      }
    }
  });

  history.push(url.pathname + url.search);
};

export const handleChooseSortItem = (history, value) => {
  let url = new URL(window.location.href);
  url.searchParams.set('sort_by', value);
  history.push(url.pathname + url.search);
};

export const isFavPage = history => history.location.pathname.split('/')[1] === 'fav';

export const getFullName = (first, second) => `${first} ${second}`;

export const setIsAdult = () => localStorage.setItem("isAdult", 'true');

export const isAdult = () => localStorage.getItem("isAdult") === 'true';

export const createCategoryViewName = (cat, subcat) => subcat === anotherSubcategory ? `${cat} / ${subcat}` : subcat;