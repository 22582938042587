import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { SideBarView } from "./SideBarView";
import { names, updateURL, isFavPage, getCurrentUrlParams, AppContext } from "../../common";

export const SideBar = ({categories, dormitories, minMax, preview}) => {
  const history = useHistory();
  const context = useContext(AppContext);

  const handleSubmit = (event) => {
    const currentParams = getCurrentUrlParams();
    const categoryIds = getSelectedCheckboxIds(names.categoriesCheckboxName);
    const dormitoryIds = getSelectedCheckboxIds(names.dormitoryCheckboxName);
    const priceRange = getSelectedPriceRange();
    const lastChooseCategories = currentParams.categories || [];
    const lastChooseDormitories = currentParams.dormitories || [];
    const isUpdate = !(
      arraysAreEqual(categoryIds, lastChooseCategories) &&
      arraysAreEqual(dormitoryIds, lastChooseDormitories)
    );
    const values = {
      'categories[]': categoryIds,
      'dormitories[]': dormitoryIds,
    };

    if(!isUpdate) {
      if(minMax[0] !== priceRange[0]) {
        values.minPrice = priceRange[0];
      }
      if(minMax[1] !== priceRange[1]) {
        values.maxPrice = priceRange[1];
      }
    }

    updateURL(history, values, null);
    context.isPhone && context.handleClickSideBar(false);
  };

  const handleReset = (event) => {
    history.push(isFavPage(history) ? "/fav/1" : "/page/1");
    context.isPhone && context.handleClickSideBar(false);
  };

  return (
    <SideBarView
      preview={preview}
      handleSubmit={handleSubmit}
      handleReset={handleReset}
      minMaxPrice={minMax}
      values={{
        categories: categories,
        dormitories: dormitories,
      }}
    />
  );
};

const getSelectedCheckboxIds = (name) => {
  let selected = [];
  document
    .getElementsByName(name)
    .forEach((e) => e.checked && selected.push(e.id.substring(1, e.id.length)));
  return selected;
};

const getSelectedPriceRange = () => {
  let priceRange = document.getElementsByName(names.priceValuesName);
  return [+priceRange[0].value, +priceRange[1].value];
};

const arraysAreEqual = (lhs, rhs) =>
  lhs &&
  rhs &&
  lhs.length === rhs.length &&
  lhs.filter((e, ind) => e != rhs[ind]).length === 0;
