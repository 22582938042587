import React, { useState, useEffect } from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import { ArrowUpward } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  buttonScrollUp: {
    position: 'fixed',
    left: '93%',
    top: '90%',
    border: `2px solid ${theme.palette.grey[400]}`,
    color: theme.palette.grey[400],
    zIndex: theme.zIndex[25],
  },
}));

export const ScrollTopButton = (props) => {
  const {className, ...otherProps} = props;
  const classes = useStyles();
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    setVisible(window.pageYOffset >= document.documentElement.clientHeight * 4);
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisible);
    return () =>  window.removeEventListener('scroll', toggleVisible);
  }, []);

  return(
    <IconButton 
      className={`${classes.buttonScrollUp} ${props.className}`}
      style={{display: visible ? 'inline' : 'none'}}
      onClick={() => window.scrollTo(0, 0)}
      {...otherProps}
    >
      <ArrowUpward/>
    </IconButton>
  )
}