import React, {useEffect, useState} from 'react';
import { Collapse, List, ListItem, ListItemText, FormControlLabel, Checkbox} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

export const SubList = ({title, name, items, classes, titleClass, prefixId, defaultSelected}) => {
    const [open, setOpen] = useState(false);
    const selectedSet = () => new Set(defaultSelected?.map(e => prefixId + e));
    const [selected, setSelected] = useState(selectedSet());

    const handleClickCollapse = () => {
      setOpen(!open);
    };

    useEffect(() => setSelected(selectedSet()), [defaultSelected]);

    const handleClick = (event) => {
        const selectedId = event.target.id;
        let newSelected;
        if(selected.has(selectedId)) {
            selected.delete(selectedId);
            newSelected = new Set(selected);
            setSelected(newSelected);
        }
        else {
            selected.add(selectedId);
            newSelected = new Set(selected);
            setSelected(newSelected);
        }
    };

    return(
        <div key={title}>
            <ListItem onClick={handleClickCollapse} className={titleClass} key={`li${title}`}>
                    <ListItemText primary={title} key={`titleText${title}`}/>
                    {open ? <ExpandLess key={`el${title}`}/> : <ExpandMore key={`em${title}`}/>}
            </ListItem>
            <Collapse in={open}>
                <List 
                    className={classes.subList}
                    component="div" 
                >
                    {items.map(e => (
                        <ListItem className={classes.subItem} key={`${prefixId + e.id}`}>
                            <FormControlLabel 
                                control={
                                    <Checkbox 
                                        id={`${prefixId + e.id}`} 
                                        name={name}
                                        key={`${prefixId + e.id}`}
                                        checked={selected.has(`${prefixId + e.id}`)}
                                        onClick={handleClick}
                                    />
                                } 
                                label={e.name}
                            />
                        </ListItem>
                    ))}
                </List>
            </Collapse>
        </div>
    )
}