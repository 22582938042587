import {makeStyles} from "@material-ui/core/styles";

const signInStyles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh'
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '30px',
    maxWidth: '60%',
    minWidth: '250px',
  },
  button: {
    marginTop: theme.spacing(1),
  },
});

export const useSignInStyles = makeStyles(signInStyles);
export const useSignInMobileStyles = makeStyles(signInStyles);
