import React from 'react';
import {IconButton, Typography, makeStyles} from '@material-ui/core';

const useStyles = makeStyles({
    button: {
        color: props => props.firstColor,
        padding: 5,
        margin: '0 5px',
        '& span': {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
        },
        '&:hover': {
            '& svg': {
                color: props => props.secondColor,
            }
        },
        '& svg': {
            transition: 'color 500ms',
        }
    },
    iconText: {
        'text-align': 'center',
    },
    root: {
    },
});

export const TextIconButton = ({onClick, icon, text, firstColor, secondColor}) => {
    const classes = useStyles({firstColor, secondColor});

    return(
        <IconButton
            className={classes.button}
            onClick={onClick}
            aria-label="show more"
            aria-haspopup="true"
            color="primary"
            disableRipple={true}
            style={{ backgroundColor: 'transparent' }}
        >
            {icon}
            <Typography className={classes.iconText} variant="caption" color='textPrimary'>{text}</Typography>
        </IconButton>
    );
}