import React, { useEffect, useState, useContext } from "react";
import { List, ListItemText, ListItem, RadioGroup, FormControlLabel, Radio, Typography, Breadcrumbs, useTheme } from "@material-ui/core";
import { useMultiItemsSelectorStyles, useMultiItemsSelectorMobileStyles } from "./multiItemsSelectorStyles";
import { AppContext } from "../../../common";
import {ErrorValidation} from "../../../components/ErrorValidation";

export const MultiItemsSelector = ({name, disabled, items, value, setFieldValue, error}) => {
  const defaultClasses = useMultiItemsSelectorStyles();
  const mobileClasses = useMultiItemsSelectorMobileStyles();

  const theme = useTheme();
  const context = useContext(AppContext);
  const classes = context.isPhone ? mobileClasses : defaultClasses;

  const [category, setCategory] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);

  useEffect(() => {
    setCategory(findCategoryNameById(value, items));
    setSubCategories(findSubCategoriesById(value, items));
    setSelectedSubCategory(findNameById(value, items));
  }, [items]);

  return (
    <div>
      <div className={classes.selectedPath}>
        <Typography variant="h6">
          Категория:&nbsp;
        </Typography>
        {!!category &&
          <Breadcrumbs>
            <Typography>{category}</Typography>
            <Typography color="textPrimary">{selectedSubCategory}</Typography>
          </Breadcrumbs>}
      </div>
      <ErrorValidation error={error}/>
      <div className={classes.mainContainer}>
        <List className={classes.leftSide}>
          {Object.keys(items).map(e => (
            <ListItem
              button
              disabled={disabled}
              selected={e === category}
              onClick={() => {
                setCategory(e);
                setSubCategories(items[e]);
                setSelectedSubCategory(null);
                setFieldValue(name, 0);
              }}
              key={e}
            >
              <ListItemText primary={e} />
            </ListItem>
          ))}
        </List>
        <RadioGroup
          style={!context.isPhone ? ({height: 48 * (items?.length ? items.length : 6) + 16}) : ({})}
          className={classes.rightSide}
          name={name}
          value={value}
          onChange={(e) => {
            setFieldValue(name, +e.target.value);
            setSelectedSubCategory(findNameById(e.target.value, items));
            setCategory(category);
          }}
        >
          {subCategories.map((e) => 
            <FormControlLabel
              className={classes.category}
              control={
                <Radio
                  style={{color: theme.palette.misis}}
                  value={e.id} checked={+e.id === +value}
                  disabled={disabled}
                />}
              label={e.name}
              value={e.name}
              key={e.name}
            />
          )}
        </RadioGroup>
      </div>
    </div>
  );
};

const findNameById = (id, items) => {
  let target = null;
  Object.values(items)
    .forEach(subCategoriesArray => subCategoriesArray
      .forEach(subCategory => {
      if(+subCategory.id === +id) {
        target = subCategory.name;
        return subCategory.name;
      }
      })
    );
  return target;
};

const findCategoryNameById = (id, items) => {
  let target = "";
  Object.keys(items)
    .forEach(categoryName => items[categoryName]
        .forEach(subCategory => {
          if(+subCategory.id === +id) {
            target = categoryName;
            return categoryName;
          } 
        })
    );
  return target;
};

const findSubCategoriesById = (id, items) => {
  let target = [];
  Object.values(items)
    .forEach(subCategoriesArray => subCategoriesArray
      .forEach(subCategory => {
        if(+subCategory.id === +id) {
          target = subCategoriesArray;
          return subCategoriesArray;
        }
      })
    );
  return target;
};